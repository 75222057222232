import { Servicos } from "pages/Auth/Servicos";
import { ServicosDisponiveis } from "pages/Servicos";
import {
  BrowserRouter as Router,
  Route,
  Routes as RoutesList
} from "react-router-dom";
import { LayoutDefault } from "../../layouts/LayoutDefault";
import { LayoutBody } from "../../layouts/LayoutDefault/Body";
import { AcessoNegado } from "../../pages/Auth/AcessoNegado";
import { CallbackPage } from "../../pages/Auth/CallbackPage";
import { Login } from "../../pages/Auth/Login";
import { Home } from "../../pages/Home";
import { Auth } from "./base/Auth";
import { Protected } from "./base/Protected";
import { LicenciamentoUrbanoRoutes } from "./modules/LicenciamentoUrbanoRoutes";
import { MapaRoutes } from "./modules/MapaRoutes";
import { ZoonoseRoutes } from "./modules/ZoonoseRoutes";

export const Routes = () => {
  return (
    <Router>
      <RoutesList>
        <Route element={<Auth />}>
          <Route path="/" element={<LayoutDefault />}>
            <Route element={<LayoutBody />}>
              <Route
                path="/servicos-disponiveis"
                element={<ServicosDisponiveis />}
              />
              <Route element={<Protected />}>
                <Route index element={<Home />} />
                <Route path="/mapa/*" element={<MapaRoutes />} />
                <Route path="/zoonose/*" element={<ZoonoseRoutes />} />
                <Route
                  path="/licenciamento-urbano/*"
                  element={<LicenciamentoUrbanoRoutes />}
                />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route path="login" element={<Login />} />
        <Route path="servicos" element={<Servicos />} />
        <Route path="auth/callback" element={<CallbackPage />} />
        <Route path="acessonegado" element={<AcessoNegado />} />
      </RoutesList>
    </Router>
  );
};
