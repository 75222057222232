import AuthOutlet from "@auth-kit/react-router/AuthOutlet";
import { useParams } from "react-router-dom";

export const Auth = () => {
  const params = useParams(); // DONT REMOVE, Faz relogar a aplicacão

  return (
    <AuthOutlet fallbackPath={`/login?callbackUrl=${window.location.href}`} />
  );
};
