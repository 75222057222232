/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, takeLeading } from "redux-saga/effects";
import { formatError } from "utils/errorHelper";
import api from "_config/api";

export interface AnimalRequest {
  id?: string;
  nome: string;
  raca: string;
  especie: number;
  sexo: number;
  porte: number;
  idadeAnos: number | null;
  idadeMeses: number | null;
  pelagem: string;
  peso: number;
  corPredominante: string;
  doencasPreexistentes: number[];
  castrado: boolean;
  desejaCastrar: boolean;
  vacinaEmDia: boolean;
  dataUltimaVacinacao: string | null;
  dataObito: string | null;
  onSuccess: () => void;
}

interface State {
  isRequesting: boolean;
  errors: string[];
}

const initialState: State = {
  isRequesting: false,
  errors: []
};

const editarAnimalSlice = createSlice({
  name: "zoonose@editarAnimal",
  initialState,
  reducers: {
    editarAnimal: (state, action: PayloadAction<AnimalRequest>): void => {
      state.isRequesting = true;
      state.errors = [];
    },
    editarAnimalSuccess: (state): void => {
      state.isRequesting = false;
    },
    editarAnimalFailed: (state, action: PayloadAction<string[]>): void => {
      state.isRequesting = false;
      state.errors = action.payload;
    }
  }
});

function* sagaFunction(params: { type: string; payload: AnimalRequest }) {
  const { onSuccess, ...rest } = params.payload;
  try {
    const response: SagaReturnType<typeof api> = yield call(
      api.put,
      `api/v1/cidadao/saude/animal`,
      rest
    );

    if (onSuccess) onSuccess();
    yield put(editarAnimalSuccess());
  } catch (error) {
    yield put(editarAnimalFailed(formatError(error)));
  }
}

export const { editarAnimal, editarAnimalSuccess, editarAnimalFailed } =
  editarAnimalSlice.actions;

export const { reducer } = editarAnimalSlice;
export const saga = takeLeading(editarAnimal.type, sagaFunction);
