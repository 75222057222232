import { combineReducers } from "redux";

import { reducer as obterFotosAnimal } from "./actions/obterFotosAnimal";
import { reducer as uploadFoto } from "./actions/uploadFotosAnimal";
import { reducer as removerFotoAnimal } from "./actions/removerFotoAnimal";

export default combineReducers({
  obterFotosAnimal,
  uploadFoto,
  removerFotoAnimal
});
