/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-children-prop */
import {
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  Text
} from "@chakra-ui/react";
import { Control, Controller, FieldError } from "react-hook-form";
import { IconType } from "react-icons";
import { TextGray } from "../../Texts/TextGray";

interface Options {
  value: string;
  label: string;
}

interface Props {
  name: string;
  defaultValue?: string[];
  direction?: "row" | "column";
  spacing?: number;
  label?: string;
  icon?: IconType;
  error?: FieldError;
  option: Options[];
  control: Control<any, object>;
  onChangeValue?: (o: (string | number)[]) => void;
}

export const InputCheckbox = ({
  name,
  defaultValue = [],
  direction = "row",
  label,
  error,
  spacing = 4,
  icon: IconComponent,
  control,
  option,
  onChangeValue
}: Props) => {
  return (
    <FormControl isInvalid={!!error}>
      {!!label && (
        <FormLabel htmlFor={name} fontSize="sm">
          <Flex alignItems="center">
            {IconComponent && (
              <TextGray as="span" mr="1.5">
                <IconComponent />
              </TextGray>
            )}
            {label}
          </Flex>
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <CheckboxGroup
            onChange={(e) => {
              field.onChange(e);
              if (onChangeValue) onChangeValue(e);
            }}
            value={field.value}
            defaultValue={defaultValue}
          >
            <Stack direction={direction} spacing={spacing}>
              {option.map((x) => (
                <Checkbox key={x.value} value={x.value}>
                  <Text fontSize="sm">{x.label}</Text>
                </Checkbox>
              ))}
            </Stack>
          </CheckboxGroup>
        )}
      />
      {!!error && (
        <FormErrorMessage mt="1" fontSize="xs">
          {error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
