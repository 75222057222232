/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { formatError } from "utils/errorHelper";
import api from "_config/api";

export interface Solicitacao {
  id: string;
  nome: string;
}

interface State {
  isRequesting: boolean;
  successPayload: Solicitacao[];
  errors: string[];
}

const initialState: State = {
  isRequesting: false,
  successPayload: [],
  errors: []
};

const obterUltimasSolicitacoesSlice = createSlice({
  name: "licenciamentourbano@obterUltimasSolicitacoes",
  initialState,
  reducers: {
    obterUltimasSolicitacoes: (state): void => {
      state.isRequesting = true;
      state.errors = [];
    },
    obterUltimasSolicitacoesSuccess: (
      state,
      action: PayloadAction<Solicitacao[]>
    ): void => {
      state.isRequesting = false;
      state.successPayload = action.payload;
    },
    obterUltimasSolicitacoesFailed: (
      state,
      action: PayloadAction<string[]>
    ): void => {
      state.isRequesting = false;
      state.errors = action.payload;
    }
  }
});

function* sagaFunction() {
  try {
    const response: SagaReturnType<typeof api> = yield call(
      api.get,
      `api/v1/cidadao/licenciamentourbano/requerimentos-solicitados`
    );

    const { data } = response;

    yield put(obterUltimasSolicitacoesSuccess(data));
  } catch (error) {
    yield put(obterUltimasSolicitacoesFailed(formatError(error)));
  }
}

export const {
  obterUltimasSolicitacoes,
  obterUltimasSolicitacoesSuccess,
  obterUltimasSolicitacoesFailed
} = obterUltimasSolicitacoesSlice.actions;

export const { reducer } = obterUltimasSolicitacoesSlice;

export const saga = takeLatest(obterUltimasSolicitacoes.type, sagaFunction);
