import { all, call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { formatError } from "../../../utils/errorHelper";
import api from "../../../_config/api";

import {
  obterConfiguracao,
  obterConfiguracaoFailed,
  ObterConfiguracaoResponse,
  obterConfiguracaoSuccess
} from "./actions/obterConfiguracao";

import {
  obterTema,
  obterTemaFailed,
  ObterTemaResponse,
  obterTemaSuccess
} from "./actions/obterTema";

function* obter() {
  try {
    const response: SagaReturnType<typeof api> = yield call(
      api.get,
      `api/v1/configuracoes`
    );

    const { data }: { data: ObterConfiguracaoResponse } = response;

    yield put(obterConfiguracaoSuccess(data));
  } catch (error) {
    yield put(obterConfiguracaoFailed(formatError(error)));
  }
}

function* obterTemaSaga() {
  try {
    const response: SagaReturnType<typeof api> = yield call(
      api.get,
      `api/v1/customizacao/tema`
    );

    const { data }: { data: ObterTemaResponse } = response;

    yield put(obterTemaSuccess(data));
  } catch (error) {
    yield put(obterTemaFailed(formatError(error)));
  }
}

export default all([
  takeLatest(obterConfiguracao.type, obter),
  takeLatest(obterTema.type, obterTemaSaga)
]);
