/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { formatError } from "utils/errorHelper";
import api from "_config/api";

export interface removerFotoAnimalRequest {
  idArquivo: string;
  idAnimal: string;
  onSuccess?: () => void;
}

interface State {
  isRequesting: boolean;
  errors: string[];
}

const initialState: State = {
  isRequesting: false,
  errors: []
};
const removerFotoAnimalSlice = createSlice({
  name: "zoonose.fotos.fotos@removerFotoAnimal",
  initialState,
  reducers: {
    removerFotoAnimal: (
      state,
      action: PayloadAction<removerFotoAnimalRequest>
    ): void => {
      state.isRequesting = true;
      state.errors = [];
    },
    removerFotoAnimalSuccess: (state): void => {
      state.isRequesting = false;
    },
    removerFotoAnimalFailed: (state, action: PayloadAction<string[]>): void => {
      state.isRequesting = false;
      state.errors = action.payload;
    }
  }
});

function* sagaFunction(params: {
  type: string;
  payload: removerFotoAnimalRequest;
}) {
  try {
    const { onSuccess, idArquivo, idAnimal } = params.payload;

    yield call(
      api.delete,
      `api/v1/cidadao/saude/animal/${idAnimal}/foto/${idArquivo}`
    );

    if (onSuccess) onSuccess();

    yield put(removerFotoAnimalSuccess());
  } catch (error) {
    yield put(removerFotoAnimalFailed(formatError(error)));
  }
}

export const {
  removerFotoAnimal,
  removerFotoAnimalSuccess,
  removerFotoAnimalFailed
} = removerFotoAnimalSlice.actions;
export const { reducer } = removerFotoAnimalSlice;
export const saga = takeLatest(removerFotoAnimal.type, sagaFunction);
