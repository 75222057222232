/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { formatError } from "utils/errorHelper";
import api from "_config/api";

export interface DownloadArquivoPendenciaRequest {
  idArquivo: string;
  onSuccess: (arquivo: ArquivoPendencia) => void;
}

export interface ArquivoPendencia {
  id: string;
  caminho: string;
  fileName: string;
  originalFileName: string;
}

interface State {
  isRequesting: string[];
  errors: string[];
}

const initialState: State = {
  isRequesting: [],
  errors: []
};

const downloadArquivoPendenciaSlice = createSlice({
  name: "licenciamentourbano@downloadArquivoPendencia",
  initialState,
  reducers: {
    downloadArquivoPendencia: (
      state,
      action: PayloadAction<DownloadArquivoPendenciaRequest>
    ): void => {
      state.isRequesting = [...state.isRequesting, action.payload.idArquivo];
      state.errors = [];
    },
    downloadArquivoPendenciaSuccess: (
      state,
      action: PayloadAction<ArquivoPendencia>
    ): void => {
      state.isRequesting = state.isRequesting.filter(
        (x) => x != action.payload.id
      );
    },
    downloadArquivoPendenciaFailed: (
      state,
      action: PayloadAction<{ id: string; error: string[] }>
    ): void => {
      state.isRequesting = state.isRequesting.filter(
        (x) => x != action.payload.id
      );
    }
  }
});

function* sagaFunction(params: {
  type: string;
  payload: DownloadArquivoPendenciaRequest;
}) {
  const { idArquivo, onSuccess } = params.payload;
  try {
    const response: SagaReturnType<typeof api> = yield call(
      api.get,
      `api/v1/cidadao/licenciamentourbano/arquivo-pendencia/${idArquivo}`
    );

    const { data } = response;
    onSuccess(data);

    yield put(downloadArquivoPendenciaSuccess(data));
  } catch (error) {
    yield put(
      downloadArquivoPendenciaFailed({
        id: params.payload.idArquivo,
        error: formatError(error)
      })
    );
  }
}

export const {
  downloadArquivoPendencia,
  downloadArquivoPendenciaSuccess,
  downloadArquivoPendenciaFailed
} = downloadArquivoPendenciaSlice.actions;

export const { reducer } = downloadArquivoPendenciaSlice;

export const saga = takeLatest(downloadArquivoPendencia.type, sagaFunction);
