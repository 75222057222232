import { Box, ButtonProps, HTMLChakraProps, Spinner } from "@chakra-ui/react";
import { forwardRef, Ref } from "react";

type Props = {
  children: React.ReactNode;
  isRequesting?: boolean;
  isFullscreen: boolean;
} & HTMLChakraProps<"div"> &
  ButtonProps;

export const ActionButton = forwardRef(function ActionButtonElement(
  { children, isFullscreen, isRequesting = false, ...props }: Props,
  ref: Ref<HTMLDivElement>
) {
  const paddingIsFullScreen = {
    normal: {
      px: isRequesting ? "2" : "1.5",
      py: isRequesting ? "0.5" : "1.5"
    },
    full: {
      px: isRequesting ? "3" : "2",
      py: isRequesting ? "3px" : "2"
    }
  };

  return (
    <Box
      as="button"
      fontSize={isFullscreen ? "1.2rem" : "1rem"}
      color="white"
      bgColor="rgba(0, 0, 0, 0.6)"
      borderRadius="md"
      disabled={isRequesting}
      ref={ref}
      {...paddingIsFullScreen[isFullscreen ? "full" : "normal"]}
      {...props}
    >
      {isRequesting ? <Spinner size="xs" color="gray.400" /> : children}
    </Box>
  );
});
