import { InputRadio } from "components/Atomos/Form/Elements/InputRadio";
import { useEffect } from "react";
import { Control, FieldError } from "react-hook-form";
import { useAppDispatch, useTypedSelector } from "store/hooks";
import { obterSexoAnimal } from "store/modules/zoonose/actions/obterSexoAnimal";

interface InputRadioSexoProps {
  error?: FieldError;
  control: Control<any, object>;
}

export const InputRadioSexo = ({ control, error }: InputRadioSexoProps) => {
  const dispatch = useAppDispatch();

  const { successPayload: sexos, isRequesting } = useTypedSelector(
    (state) => state.zoonose.obterSexoAnimal
  );

  useEffect(() => {
    dispatch(obterSexoAnimal());
  }, []);

  return (
    <InputRadio
      direction="row"
      spacing={2}
      name="sexo"
      label="Sexo"
      option={sexos.map((x) => ({
        value: String(x.value),
        label: x.name
      }))}
      isDisabled={isRequesting}
      control={control}
      error={error}
      size="sm"
    />
  );
};
