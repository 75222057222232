import { Tag, TagLabel, TagProps } from "@chakra-ui/react";

type Props = {
  id: string;
  nome: string;
  size?: "md" | "sm";
} & TagProps;

export const StatusRequerimentoTag = ({
  id,
  nome,
  size = "md",
  ...rest
}: Props) => {
  const statusColor = {
    "1": "#BEE3F8",
    "2": "#FEFCBF",
    "3": "#FEEBC8",
    "4": "#C6F6D5",
    "5": "#FED7D7"
  } as { [key: string]: string };

  return (
    <Tag
      size={size}
      style={{ backgroundColor: statusColor[id] }}
      color="black"
      {...rest}
    >
      <TagLabel>{nome}</TagLabel>
    </Tag>
  );
};
