/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { formatError } from "utils/errorHelper";
import api from "_config/api";

export interface ObterDetalheSolicitacaoRequest {
  idSolicitacao: string;
}

export interface DetalheSolicitacao {
  id: string;
  nomeRequerimento: string;
  nomeUsuario: string;
  cpf: string;
  email: string;
  telefone?: string;
  protocolo: string;
  dataEnvioFormatada?: string;
  dataUltimaAtualizacaoFormatada?: string;
  status?: {
    value: number;
    name: string;
  };
  informacoesRequerimentos: {
    enunciado: string;
    resposta: string;
  }[];
  arquivos: {
    id: string;
    idResposta: string;
    nome: string;
    originalFileName?: string;
  }[];
  etapas: {
    idEtapa: string;
    isConcluida: boolean;
    isAtual: boolean;
    nome: string;
    dataConclusaoFormatada?: string | null;
    responsavelConclusao?: string | null;
    ordem: number;
    equipe?: string;
    observacao?: string;
    situacaoEtapaSolicitacao?: {
      value: number;
      name: string;
    };
    arquivosConclusao: {
      id: string;
      idResposta: string;
      nome: string;
      originalFileName?: string;
      idSolicitacaoEtapa?: string;
    }[];
  }[];
  pendencias: {
    id: string;
    isResolvida: boolean;
    status?: string;
    dataFormatada?: string;
    comentario?: string;
    observacao?: string;
    responsavel?: string;
    destinatario?: string;
    respostas: {
      responsavel?: string;
      comentario?: string;
      isColaborador?: boolean;
      dataCriacao?: string;
      dataCriacaoFormatada?: string;
      arquivos?: {
        id: string;
        idResposta: string;
        nome?: string;
        originalFileName?: string;
      }[];
    }[];
    arquivos?: {
      id: string;
      idResposta: string;
      nome?: string;
      originalFileName?: string;
    }[];
  }[];
}

interface State {
  isRequesting: boolean;
  successPayload: DetalheSolicitacao | null;
  errors: string[];
}

const initialState: State = {
  isRequesting: false,
  successPayload: null,
  errors: []
};

const obterDetalheSolicitacaoSlice = createSlice({
  name: "licenciamentourbano@obterDetalheSolicitacao",
  initialState,
  reducers: {
    obterDetalheSolicitacao: (
      state,
      action: PayloadAction<ObterDetalheSolicitacaoRequest>
    ): void => {
      state.isRequesting = true;
      state.errors = [];
    },
    obterDetalheSolicitacaoSuccess: (
      state,
      action: PayloadAction<DetalheSolicitacao>
    ): void => {
      state.isRequesting = false;
      state.successPayload = action.payload;
    },
    obterDetalheSolicitacaoFailed: (
      state,
      action: PayloadAction<string[]>
    ): void => {
      state.isRequesting = false;
      state.errors = action.payload;
    }
  }
});

function* sagaFunction(params: {
  type: string;
  payload: ObterDetalheSolicitacaoRequest;
}) {
  try {
    const response: SagaReturnType<typeof api> = yield call(
      api.get,
      `api/v1/cidadao/licenciamentourbano/detalhe/${params.payload.idSolicitacao}`
    );

    const { data } = response;

    yield put(obterDetalheSolicitacaoSuccess(data));
  } catch (error) {
    yield put(obterDetalheSolicitacaoFailed(formatError(error)));
  }
}

export const {
  obterDetalheSolicitacao,
  obterDetalheSolicitacaoSuccess,
  obterDetalheSolicitacaoFailed
} = obterDetalheSolicitacaoSlice.actions;

export const { reducer } = obterDetalheSolicitacaoSlice;

export const saga = takeLatest(obterDetalheSolicitacao.type, sagaFunction);
