import { combineReducers } from "redux";
import { reducer as oidc } from "redux-oidc";
import { auth } from "./auth/reducer";
import { mapa } from "./mapa/reducer";
import { configuracao } from "./configuracao/reducer";
import { zoonose } from "./zoonose/reducer";
import { licenciamentourbano } from "./licenciamentourbano/reducer";

const rootReducer = combineReducers({
  oidc,
  auth,
  mapa,
  configuracao,
  zoonose,
  licenciamentourbano
});
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
