import { InputSelect } from "components/Atomos/Form/Elements/InputSelect";
import { useEffect } from "react";
import { Control, FieldError } from "react-hook-form";
import { useAppDispatch, useTypedSelector } from "store/hooks";
import { obterPorteAnimal } from "store/modules/zoonose/actions/obterPorteAnimal";

type Props = {
  control: Control<any, object>;
  error?: FieldError;
};

export const InputSelectPorte = ({ control, error }: Props) => {
  const dispatch = useAppDispatch();

  const { successPayload, isRequesting } = useTypedSelector(
    (state) => state.zoonose.obterPorteAnimal
  );

  useEffect(() => {
    dispatch(obterPorteAnimal());
  }, []);

  return (
    <InputSelect
      label="Porte (Opcional)"
      name="porte"
      control={control}
      error={error}
      placeholder="Selecione"
      option={[
        ...(successPayload
          ? successPayload.map((item) => ({
              value: String(item.value),
              label: String(item.name)
            }))
          : [])
      ]}
      isLoading={isRequesting}
    />
  );
};
