import { Store } from "redux";
import createSagaMiddleware from "redux-saga";
import { injectStoreMock } from "../_config/apiMock";
import "../_config/ReactotronConfig";
import createStore from "./createStore";
import rootReducer from "./modules/rootReducer";
import rootSaga from "./modules/rootSaga";

const sagaMonitor =
  process.env.NODE_ENV === "development"
    ? console.tron.createSagaMonitor()
    : null;

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

const store: Store = createStore(rootReducer, [sagaMiddleware]);
sagaMiddleware.run(rootSaga);

injectStoreMock(store);

export { store };
