/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-children-prop */
import React from "react";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Box
} from "@chakra-ui/react";
import { Control, Controller, FieldError } from "react-hook-form";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import { BoundRedirector } from "components/Organismos/Map/Controls/BoundRedirector";
import "leaflet/dist/leaflet.css";

interface Props {
  name: string;
  label?: string;
  control: Control<any>;
  error?: FieldError;
}

export const InputMap: React.FC<Props> = ({ name, label, error, control }) => {
  return (
    <FormControl isInvalid={!!error}>
      {!!label && (
        <FormLabel htmlFor={name}>
          <Flex alignItems="center">{label}</Flex>
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { ref, value, onChange, ...restField } }) => {
          return (
            <Box
              h="64"
              w="full"
              borderRadius="md"
              overflow="hidden"
              border={error ? "2px solid #E53E3E" : ""}
            >
              <MapContainer
                style={{
                  height: "100%",
                  width: "100%"
                }}
                zoomControl={false}
                preferCanvas={true}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <BoundRedirector />
                {value && (
                  <Marker
                    position={{
                      lat: value.lat,
                      lng: value.lng
                    }}
                  />
                )}

                <ClickHandler
                  onClick={({ lat, lng }) => {
                    onChange({ lat, lng });
                  }}
                />
              </MapContainer>
            </Box>
          );
        }}
      />
      {!!error && (
        <FormErrorMessage mt="0.5" fontSize="xs">
          {error.message as string}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

const ClickHandler = ({
  onClick
}: {
  onClick: ({ lat, lng }: { lat: number; lng: number }) => void;
}) => {
  useMapEvents({
    click: (e) => {
      onClick({ lat: e.latlng.lat, lng: e.latlng.lng });
    }
  });

  return null;
};
