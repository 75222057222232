import { CSSProperties } from "react";
import { StylesConfig } from "react-select";

export const createColourStyles = (
  bgColor: string,
  bgOption: string,
  isColor: boolean
): StylesConfig => {
  const customControlStyles: CSSProperties = {
    backgroundColor: bgColor,
    borderWidth: "1px",
    borderColor: isColor ? "whiteAlpha.200" : "rgb(23, 25, 35, 0.05)",
    borderRadius: "0.375rem",
    padding: "0.065rem"
  };

  return {
    control: (provided, state) => ({
      ":hover": {
        ...provided[":hover"],
        borderColor: isColor ? "" : "whiteAlpha"
      },
      ...provided,
      ...customControlStyles
    }),
    menuList: (provided, state) => ({
      ...provided,
      backgroundColor: bgOption,
      borderColor: isColor ? "whiteAlpha.200" : "rgb(23, 25, 35, 0.05)"
    }),
    option: (provided, state) => ({
      ...provided,
      ":hover": {
        ...provided[":hover"],
        backgroundColor: "#1867d3",
        color: isColor ? "white" : "whiteAlpha.900"
      }
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: isColor ? "#000" : "white"
    }),

    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: "#d8d8d8",
      color: "#000"
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: isColor ? "whiteAlpha.600" : "whiteAlpha.400"
    }),
    input: (provided, state) => ({
      ...provided,
      color: isColor ? "black" : "white"
    })
  };
};
