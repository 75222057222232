import { Route, Routes } from "react-router-dom";
import { SolicitarRequerimentoInicio } from "pages/LicenciamentoUrbano/SolicitarRequerimento";
import { DetalharRequerimentoInicio } from "pages/LicenciamentoUrbano/DetalharRequerimento";
import { MeusRequerimentos } from "pages/LicenciamentoUrbano/MeusRequerimentos";

export const LicenciamentoUrbanoRoutes = () => {
  return (
    <Routes>
      <Route
        path="/solicitar-requerimento/:id"
        element={<SolicitarRequerimentoInicio />}
      />
      <Route
        path="/requerimento/:id"
        element={<DetalharRequerimentoInicio />}
      />
      <Route path="/meus-requerimentos" element={<MeusRequerimentos />} />
    </Routes>
  );
};
