/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import axios from "axios";
import { getFullPathEnv } from "utils/pathEnv";

const api = axios.create({ baseURL: getFullPathEnv("REACT_APP_API") });

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status == 401) {
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default api;
