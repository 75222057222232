/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-children-prop */
import React from "react";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberInputProps,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { TextGray } from "../../Texts/TextGray";
import { Control, Controller, FieldError } from "react-hook-form";
import { RiAddFill, RiSubtractFill } from "react-icons/ri";

interface Props extends NumberInputProps {
  name: string;
  label?: string;
  icon?: IconType;
  error?: FieldError;
  control: Control<any>;
  textAlign?: "left" | "center" | "right";
  maxW?: string;
  removeControls?: boolean;
}

const InputBase: React.FC<Props> = ({
  name,
  label,
  control,
  error = null,
  icon: IconComponent,
  precision = 0,
  textAlign = "left",
  maxW,
  removeControls = false,
  ...props
}) => {
  const format = (valueAsNumber: number) => {
    if (valueAsNumber == null || valueAsNumber == undefined) return "";
    return valueAsNumber.toString();
  };

  const parse = (valueAsString: string) => {
    return valueAsString;
  };

  return (
    <FormControl isInvalid={!!error} maxW={maxW}>
      {!!label && (
        <FormLabel htmlFor={name}>
          <Flex alignItems="center">
            {IconComponent && (
              <TextGray as="span" mr="1.5">
                <IconComponent />
              </TextGray>
            )}
            {label}
          </Flex>
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { ref, value, onChange, ...restField } }) => (
          <NumberInput
            precision={precision}
            value={format(value)}
            onChange={(t) => onChange(parse(t))}
            isValidCharacter={(t) => new RegExp(/^[Ee0-9]$/).test(t)}
            {...props}
            {...restField}
          >
            <NumberInputField
              pattern="^-?[0-9]\d*(\d+)?$"
              textAlign={textAlign}
              ref={ref}
              name={restField.name}
            />
            {!removeControls && (
              <NumberInputStepper>
                <NumberIncrementStepper
                  children={<RiAddFill />}
                  fontWeight="bold"
                />
                <NumberDecrementStepper
                  children={<RiSubtractFill />}
                  fontWeight="bold"
                />
              </NumberInputStepper>
            )}
          </NumberInput>
        )}
      />
      {!!error && (
        <FormErrorMessage mt="0.5" fontSize="xs">
          {error.message as string}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export const InputNumber = InputBase;
