/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ObterTemaResponse {
  nome: string;
  cores: { [x: number]: string };
}

interface State {
  isRequesting: boolean;
  successPayload: ObterTemaResponse | null;
  errors: string[];
}

const initialState: State = {
  isRequesting: false,
  successPayload: null,
  errors: []
};

const obterTemaSlice = createSlice({
  name: "tema@obterTema",
  initialState,
  reducers: {
    obterTema: (state): void => {
      state.isRequesting = true;
      state.errors = [];
    },
    obterTemaSuccess: (
      state,
      action: PayloadAction<ObterTemaResponse>
    ): void => {
      state.isRequesting = false;
      state.successPayload = action.payload;
    },
    obterTemaFailed: (state, action: PayloadAction<string[]>): void => {
      state.isRequesting = false;
      state.errors = action.payload;
    }
  }
});

export const { obterTema, obterTemaSuccess, obterTemaFailed } =
  obterTemaSlice.actions;
export default obterTemaSlice.reducer;
