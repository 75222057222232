/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Flex, Grid, Select, Skeleton, Text } from "@chakra-ui/react";
import { InputSearch } from "components/Atomos/Form/Elements/InputSearch";
import { Title } from "components/Atomos/Texts/Title";
import { RequerimentoCard } from "components/Moleculas/Cards/RequerimentoCard";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useTypedSelector } from "store/hooks";
import { obterMinhasSolicitacoes } from "store/modules/licenciamentourbano/actions/obterMinhasSolicitacoes";

export const MeusRequerimentos = () => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const [orderAsc, setOrderAsc] = useState<"desc" | "asc">("desc");
  const [servico, setServico] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    dispatch(obterMinhasSolicitacoes());
  }, []);
  const {
    successPayload: minhasSolicitacoes,
    isRequesting: pendingMinhasSolicitacoes
  } = useTypedSelector(
    (state) => state.licenciamentourbano.obterMinhasSolicitacoes
  );

  const servicos = useMemo(() => {
    return Array.from(new Set(minhasSolicitacoes.map((s) => s.nome)));
  }, [minhasSolicitacoes]);

  const listaStatus = useMemo(() => {
    return Array.from(
      new Set(
        minhasSolicitacoes
          .filter((x) => !!x.status?.value)
          .sort((a, b) => a.status!.value - b.status!.value)
          .map((s) => s.status?.name)
      )
    );
  }, [minhasSolicitacoes]);

  const solicitacoesFiltradas = useMemo(() => {
    return minhasSolicitacoes
      .filter((s) => {
        return (
          (s.protocolo.toLowerCase().includes(search.toLowerCase()) ||
            s.nome.toLowerCase().includes(search.toLowerCase())) &&
          (servico ? s.nome === servico : true) &&
          (status ? s.status?.name === status : true)
        );
      })
      .sort((a, b) => {
        if (orderAsc === "asc") {
          return (
            new Date(a.dataSolicitacao).getTime() -
            new Date(b.dataSolicitacao).getTime()
          );
        }
        return (
          new Date(b.dataSolicitacao).getTime() -
          new Date(a.dataSolicitacao).getTime()
        );
      });
  }, [minhasSolicitacoes, orderAsc, search, servico, status]);

  return (
    <Box maxW="1200px" mx="auto" pt="20" px="8">
      <Title>Meus requerimentos</Title>
      <Text mb={2} lineHeight="1.2">
        Confira abaixo a lista de serviços disponíveis
      </Text>
      <Flex my="4" gap="2" direction={{ base: "column", lg: "row" }}>
        <InputSearch onChange={(e) => setSearch(e.target.value)} />

        <Flex
          gap="2"
          w="full"
          justifyContent="space-between"
          direction={{ base: "column-reverse", md: "row" }}
        >
          <Flex gap="2" direction={{ base: "column", md: "row" }}>
            <Box>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                placeholder="Status"
                minWidth="150px"
              >
                {listaStatus.map((s) => (
                  <option key={s} value={s}>
                    {s}
                  </option>
                ))}
              </Select>
            </Box>
            <Box>
              <Select
                value={servico}
                onChange={(e) => setServico(e.target.value)}
                placeholder="Serviço"
                minWidth="150px"
              >
                {servicos.map((s) => (
                  <option key={s} value={s}>
                    {s}
                  </option>
                ))}
              </Select>
            </Box>
          </Flex>
          <Box>
            <Select
              value={orderAsc}
              onChange={(e) => setOrderAsc(e.target.value as "desc" | "asc")}
              minWidth="150px"
            >
              <option value="desc">Mais recente</option>
              <option value="asc">Menos recente</option>
            </Select>
          </Box>
        </Flex>
      </Flex>
      <Text fontSize="sm" mb="2">
        Exibindo <Text as="strong">{solicitacoesFiltradas.length}</Text>{" "}
        resultados
      </Text>
      <Grid
        templateColumns={{ base: "1fr", md: "1fr 1fr" }}
        gap={6}
        overflowY="auto"
        maxH="40vh"
        p="1"
      >
        {solicitacoesFiltradas.map((s) => (
          <Flex
            key={s.id}
            direction="column"
            justifyContent="space-between"
            p="6"
            rounded="4px"
            bgColor="white"
          >
            <RequerimentoCard
              id={s.id}
              title={s.nome}
              data={s.dataSolicitacaoFormatada}
              protocolo={s.protocolo}
              status={s.status}
            />
          </Flex>
        ))}
        {pendingMinhasSolicitacoes && (
          <Box p="3" px="5">
            <Skeleton h="10" w="full" />
            <Skeleton h="20" w="full" />
          </Box>
        )}
      </Grid>
    </Box>
  );
};
