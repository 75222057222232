/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-children-prop */
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Spinner
} from "@chakra-ui/react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { IconType } from "react-icons";
import { TextGray } from "../../Texts/TextGray";

interface Props extends InputProps {
  name: string;
  label?: string;
  icon?: IconType;
  error?: FieldError;
  register: UseFormRegister<any>;
  isLoading?: boolean;
  isDisabled?: boolean;
}

export const InputText = ({
  name,
  label,
  register,
  error,
  icon: IconComponent,
  isLoading = false,
  isDisabled = false,
  ...props
}: Props) => {
  return (
    <FormControl isInvalid={!!error}>
      {!!label && (
        <FormLabel htmlFor={name} fontSize="sm">
          <Flex alignItems="center">
            {IconComponent && (
              <TextGray as="span" mr="1.5">
                <IconComponent />
              </TextGray>
            )}
            {label}
          </Flex>
        </FormLabel>
      )}
      <InputGroup>
        <Input
          id={name}
          {...register(name)}
          {...props}
          isDisabled={isLoading || isDisabled}
        />
        {isLoading && (
          <InputRightElement
            children={<Spinner size="sm" color="gray.400" />}
          />
        )}
      </InputGroup>
      {!!error && (
        <FormErrorMessage mt="1" fontSize="xs">
          {error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
