import { Box, Flex, Text } from "@chakra-ui/react";
import { Logo } from "layouts/components/Logo";
import React from "react";
import { FaUserLock } from "react-icons/fa";
import { RiArrowRightLine, RiUser3Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { getFullPathEnv } from "utils/pathEnv";

import { Banner } from "./Banner";

export const Login: React.FC = () => {
  const navigate = useNavigate();

  function redirectLogin(callbackUrl: string) {
    window.location.href = `${getFullPathEnv(
      "REACT_APP_IDENTITY_URL",
      `login?callbackUrl=${callbackUrl}`
    )}`;
  }

  return (
    <Flex
      h="full"
      pt="20"
      direction="column"
      justifyContent="space-between"
      bgColor="graybase.500"
    >
      <Box maxW="1000px" mx="auto">
        <Logo maxW="150px" />
        <Flex gap={12}>
          <Box flex={1}>
            <Text fontWeight="bold" fontSize="3.5rem" mb={4} lineHeight="1.2">
              Consulte todas as informações do seu município
            </Text>
            <Text fontSize="xl" lineHeight="1.2" mb="16">
              Explore detalhes e serviços disponíveis na sua cidade
            </Text>
            <Banner />
          </Box>
          <Box flex={1} borderRadius="lg" p="8" bgColor="white" boxShadow="sm">
            <Text fontSize="2.5rem" mb={2} lineHeight="1.2">
              Qual área deseja acessar?
            </Text>
            <Text fontSize="xl" lineHeight="1.2" mb="16">
              Escolha a área que corresponde ao seu perfil de acesso.
            </Text>
            <Flex
              borderStyle="solid"
              mb="6"
              p="6"
              gap="6"
              borderWidth="1px"
              borderColor="gray.200"
              alignItems="center"
              rounded="4px"
              _hover={{ bgColor: "gray.50", cursor: "pointer" }}
              onClick={() => {
                navigate("/servicos");
              }}
            >
              <RiUser3Line size={28} />
              <Box>
                <Text fontWeight="medium" fontSize="lg">
                  Área do cidadão
                </Text>
                <Text lineHeight="5">
                  Explore as informações disponíveis sobre sua cidade
                </Text>
              </Box>
              <Box
                borderWidth="1px"
                borderColor="main.500"
                p="2"
                rounded="full"
                color="main.500"
              >
                <RiArrowRightLine />
              </Box>
            </Flex>
            <Flex
              borderStyle="solid"
              p="6"
              gap="6"
              borderWidth="1px"
              borderColor="gray.200"
              alignItems="center"
              rounded="4px"
              _hover={{ bgColor: "gray.50", cursor: "pointer" }}
              onClick={() => {
                redirectLogin(`${window.location.origin}/interno`);
              }}
            >
              <FaUserLock size={28} />
              <Box>
                <Text fontWeight="medium" fontSize="lg">
                  Área administrativa
                </Text>
                <Text lineHeight="5">
                  Acesse ferramentas de gerenciamento administrativas.
                </Text>
              </Box>
              <Box
                borderWidth="1px"
                borderColor="main.500"
                p="2"
                rounded="full"
                color="main.500"
              >
                <RiArrowRightLine />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Box w="full" bgColor="white" fontSize="sm" textAlign="center" py="2">
        Sistema de Informações Territoriais - EGL Engenharia
      </Box>
    </Flex>
  );
};
