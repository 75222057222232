import { Box, Flex, Skeleton, Text, VStack } from "@chakra-ui/react";
import { BackButton } from "components/Atomos/Buttons/BackButton";
import { Title } from "components/Atomos/Texts/Title";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useTypedSelector } from "store/hooks";
import { obterDetalheTipoRequerimento } from "store/modules/licenciamentourbano/actions/obterDetalheTipoRequerimento";
import { FormRequerimento } from "./components/FormRequerimento";

export const SolicitarRequerimentoInicio = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const { successPayload, isRequesting } = useTypedSelector(
    (state) => state.licenciamentourbano.obterDetalheTipoRequerimento
  );

  useEffect(() => {
    if (id) {
      dispatch(obterDetalheTipoRequerimento({ idTipoRequerimento: id }));
    }
  }, [id]);

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      bgColor="graybase.500"
      boxShadow="sm"
      direction="column"
      overflowY="auto"
    >
      <Flex
        flex={1}
        pb="12"
        direction="column"
        maxW="container.xl"
        w="full"
        pt={{ base: 8 }}
        px={{ base: 6, sm: 8, xl: 16 }}
      >
        <BackButton to="/" />
        <Box my="6">
          <Title>Requerimento</Title>
        </Box>
        <Box
          bgColor="white"
          maxW="container.md"
          mx="auto"
          px="12"
          py="8"
          w="full"
        >
          {isRequesting && (
            <VStack w="full">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((_, i) => (
                <Skeleton h="12" w="full" key={i} />
              ))}
            </VStack>
          )}
          <Text as="h1" fontSize="3xl" fontWeight="semibold" mb="2">
            {successPayload?.nome}
          </Text>
          <Text mb="5">{successPayload?.descricao}</Text>
          {id &&
            successPayload?.secoes.map((s) => (
              <FormRequerimento
                key={s.id}
                idTipoRequerimento={id}
                questoes={s.questoes}
              />
            ))}
        </Box>
      </Flex>
    </Flex>
  );
};
