import { Circle, HStack } from "@chakra-ui/react";

type Props = {
  length: number;
  index: number;
  isFullscreen: boolean;
};

export const IndexBar = ({ isFullscreen, length, index }: Props) => {
  const arr = Array.from(Array(length).keys());

  return (
    <HStack
      spacing="0.1875rem"
      zIndex={10}
      position="absolute"
      top="90%"
      left="0"
      right="0"
      justifyContent="center"
    >
      {arr.map((x, i) =>
        i == index ? (
          <Circle
            key={i}
            size={isFullscreen ? "0.9375rem" : "0.625rem"}
            bgColor="white"
            border="2px solid rgba(0, 0, 0, 0.6)"
          ></Circle>
        ) : (
          <Circle
            key={i}
            size={isFullscreen ? "0.9375rem" : "0.625rem"}
            bgColor="rgba(0, 0, 0, 0.6)"
          ></Circle>
        )
      )}
    </HStack>
  );
};
