/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { formatError } from "utils/errorHelper";
import { transformRequestOptions } from "utils/queryString";
import api from "_config/api";

interface ObterEspecieResponse {
  value: number;
  name: string;
}

interface State {
  isRequesting: boolean;
  successPayload: ObterEspecieResponse[];
  errors: string[];
}

const initialState: State = {
  isRequesting: false,
  successPayload: [],
  errors: []
};

const obterEspecieAnimalSlice = createSlice({
  name: "zoonose@obterEspecieAnimal",
  initialState,
  reducers: {
    obterEspecieAnimal: (state): void => {
      state.isRequesting = true;
      state.errors = [];
    },
    obterEspecieAnimalSuccess: (
      state,
      action: PayloadAction<ObterEspecieResponse[]>
    ): void => {
      state.isRequesting = false;
      state.successPayload = action.payload;
    },
    obterEspecieAnimalFailed: (
      state,
      action: PayloadAction<string[]>
    ): void => {
      state.isRequesting = false;
      state.errors = action.payload;
    }
  }
});

function* sagaFunction() {
  try {
    const response: SagaReturnType<typeof api> = yield call(
      api.get,
      `api/v1/cidadao/saude/listar-especie`
    );

    const { data } = response;

    yield put(obterEspecieAnimalSuccess(data));
  } catch (error) {
    yield put(obterEspecieAnimalFailed(formatError(error)));
  }
}

export const {
  obterEspecieAnimal,
  obterEspecieAnimalSuccess,
  obterEspecieAnimalFailed
} = obterEspecieAnimalSlice.actions;

export const { reducer } = obterEspecieAnimalSlice;

export const saga = takeLatest(obterEspecieAnimal.type, sagaFunction);
