import { Flex, Text } from "@chakra-ui/react";
import { TextGray } from "components/Atomos/Texts/TextGray";

import { DropzoneOptions, useDropzone } from "react-dropzone";

type Props = {
  message: string;
  subMessage?: string;
} & DropzoneOptions;

export const InputDropzone = (props: Props) => {
  const { getRootProps, getInputProps } = useDropzone(props);

  return (
    <Flex
      borderWidth="2px"
      borderStyle="dashed"
      borderRadius="lg"
      h="full"
      px="8"
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      direction="column"
      borderColor="gray.200"
      bgColor="gray.50"
      _dark={{ bgColor: "gray.800", borderColor: "gray.700" }}
      {...getRootProps()}
    >
      <Text fontWeight="medium">{props.message}</Text>
      {props.subMessage && (
        <TextGray fontSize="sm">{props.subMessage}</TextGray>
      )}
      {props.maxFiles && props.maxFiles > 1 && (
        <Text fontSize="xs" mt="1">
          Máximo de {props.maxFiles} arquivos
        </Text>
      )}
      <input {...getInputProps()} />
    </Flex>
  );
};
