import {
  Box,
  Text,
  Button,
  Grid,
  Alert,
  BoxProps,
  Skeleton,
  Flex
} from "@chakra-ui/react";
import { InputSearch } from "components/Atomos/Form/Elements/InputSearch";
import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useTypedSelector } from "store/hooks";
import { useAuth } from "store/hooks/useAuth";
import { obterTiposRequerimentos } from "store/modules/licenciamentourbano/actions/obterTiposRequerimentos";
import { getFullPathEnv } from "utils/pathEnv";

export const ServicosList: React.FC<{
  container?: BoxProps;
  card?: BoxProps;
  title?: React.ReactNode;
}> = ({ container, card, title }) => {
  const usuario = useAuth();
  const navigate = useNavigate();
  const [search, setSearch] = React.useState("");
  const dispatch = useAppDispatch();

  const { successPayload, isRequesting } = useTypedSelector(
    (state) => state.licenciamentourbano.obterTiposRequerimentos
  );

  useEffect(() => {
    dispatch(obterTiposRequerimentos());
  }, []);

  function redirectLogin(to: string) {
    if (usuario) {
      navigate(to);
      return;
    }
    window.location.href = `${getFullPathEnv(
      "REACT_APP_IDENTITY_URL",
      `login?callbackUrl=${window.location.origin}${to}`
    )}`;
  }

  const result = useMemo(() => {
    const tiposRequerimentos = successPayload.map((t) => ({
      titulo: t.nome,
      to: `/licenciamento-urbano/solicitar-requerimento/${t.id}`,
      descricao: t.descricao
    }));
    return [...tiposRequerimentos].filter((s) =>
      s.titulo.toLowerCase().includes(search.toLowerCase())
    );
  }, [search, successPayload]);

  return (
    <Box flex={1}>
      <Box borderRadius="lg" {...container}>
        {title}

        <Box my="4">
          <InputSearch onChange={(e) => setSearch(e.target.value)} />
        </Box>
        <Text fontSize="sm" mb="2">
          Exibindo <Text as="strong">{result.length}</Text> resultados
        </Text>
        {!isRequesting && result.length == 0 && (
          <Alert>Nenhum serviço encontrado</Alert>
        )}

        <Grid
          templateColumns={{ base: "1fr", md: "1fr 1fr" }}
          gap={6}
          overflowY="auto"
          maxH="40vh"
          p="1"
        >
          {result.map((r) => (
            <Flex
              direction="column"
              justifyContent="space-between"
              key={r.titulo}
              p="3"
              px="5"
              rounded="4px"
              {...card}
            >
              <Box>
                <Text fontWeight="medium" fontSize="xl">
                  {r.titulo}
                </Text>
                <Text noOfLines={2}>{r.descricao}</Text>
              </Box>
              <Box>
                <Button
                  variant="main"
                  mt="4"
                  onClick={() => redirectLogin(r.to)}
                >
                  Solicitar
                </Button>
              </Box>
            </Flex>
          ))}
          {isRequesting && (
            <Box p="3" px="5">
              <Skeleton h="10" w="full" />
              <Skeleton h="20" w="full" />
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
