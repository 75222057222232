import { Box, Button, Flex, Skeleton, Text, VStack } from "@chakra-ui/react";
import { Title } from "components/Atomos/Texts/Title";
import { RequerimentoCard } from "components/Moleculas/Cards/RequerimentoCard";
import { Carousel } from "components/Moleculas/Carousel/Carousel";
import { ServicosList } from "components/Organismos/ServicosList";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useTypedSelector } from "store/hooks";
import { useAuth } from "store/hooks/useAuth";
import { obterMinhasSolicitacoes } from "store/modules/licenciamentourbano/actions/obterMinhasSolicitacoes";
import { obterUltimasSolicitacoes } from "store/modules/licenciamentourbano/actions/obterUltimasSolicitacoes";
import { MenuList } from "components/Organismos/MenuList";

export const Home = () => {
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    successPayload: ultimasSolicitacoes,
    isRequesting: pendingUltimasSolicitacoes
  } = useTypedSelector(
    (state) => state.licenciamentourbano.obterUltimasSolicitacoes
  );

  const {
    successPayload: minhasSolicitacoes,
    isRequesting: pendingMinhasSolicitacoes
  } = useTypedSelector(
    (state) => state.licenciamentourbano.obterMinhasSolicitacoes
  );

  useEffect(() => {
    dispatch(obterMinhasSolicitacoes());
    dispatch(obterUltimasSolicitacoes());
  }, []);

  return (
    <Box maxW="1200px" mx="auto" pt="20" px="8">
      <Title>Olá, {auth?.nome || ""}</Title>
      <Text mb="6">
        Estamos aqui para ajudar você a acessar os serviços da sua cidade.
      </Text>
      <Flex gap="4" direction={{ base: "column", lg: "row" }}>
        <Box flex={0.5}>
          <Box borderRadius="lg" bgColor="white" boxShadow="sm" p="8">
            <Text fontSize="xl" fontWeight="medium" mb={4} lineHeight="1.2">
              Meus requerimentos
            </Text>
            <VStack gap="2" overflowY="auto" maxH="40vh" p="1">
              {pendingMinhasSolicitacoes && <Skeleton h="20" w="full" />}
              {minhasSolicitacoes.map((s) => (
                <Box
                  key={s.id}
                  borderStyle="solid"
                  borderWidth={1}
                  borderColor="gray.200"
                  p="3"
                  w="full"
                  borderRadius="md"
                >
                  <RequerimentoCard
                    id={s.id}
                    title={s.nome}
                    data={s.dataSolicitacaoFormatada}
                    protocolo={s.protocolo}
                    status={s.status}
                  />
                </Box>
              ))}
            </VStack>
            <Button
              variant="main"
              mt="4"
              w="full"
              onClick={() =>
                navigate("/licenciamento-urbano/meus-requerimentos")
              }
            >
              Ver todos os requerimentos
            </Button>
          </Box>
          <Box mt="4">
            {pendingUltimasSolicitacoes && <Skeleton h="20" w="full" />}
            {ultimasSolicitacoes && ultimasSolicitacoes.length > 0 && (
              <Carousel
                title={
                  <Text fontSize="xl" fontWeight="medium" lineHeight="1.2">
                    Solicitar novamente
                  </Text>
                }
                items={ultimasSolicitacoes.map((s) => (
                  <Box key={1} bgColor="white" h="full" w="full" p="4">
                    <Text fontWeight="medium">{s.nome}</Text>
                    <Button
                      variant="mainOutline"
                      size="sm"
                      mt="4"
                      onClick={() =>
                        navigate(
                          `/licenciamento-urbano/solicitar-requerimento/${s.id}`
                        )
                      }
                    >
                      Solicitar
                    </Button>
                  </Box>
                ))}
              />
            )}
          </Box>
        </Box>
        <Flex flex={1} direction="column" gap={4}>
          <MenuList />
          <ServicosList
            container={{ bgColor: "white", boxShadow: "sm", p: "8" }}
            card={{ bgColor: "gray.100" }}
            title={
              <Text fontSize="xl" fontWeight="medium" mb={2} lineHeight="1.2">
                Serviços disponíveis
              </Text>
            }
          />
        </Flex>
      </Flex>
    </Box>
  );
};
