/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, takeLatest } from "redux-saga/effects";

import { formatError } from "utils/errorHelper";
import { transformRequestOptions } from "utils/queryString";
import api from "_config/api";

interface FotosAnimalRequest {
  idAnimal: string;
}

interface Foto {
  id: string;
  caminho: string;
  nome: string;
  size: number;
  sizeFmt: string;
}

interface State {
  isRequesting: boolean;
  successPayload: Foto[];
  trechosIds: string[];
  errors: string[];
}

const initialState: State = {
  isRequesting: false,
  successPayload: [],
  trechosIds: [],
  errors: []
};

const obterFotosAnimalSlice = createSlice({
  name: "zoonose@obterFotosAnimal",
  initialState,
  reducers: {
    obterFotosAnimal: (
      state,
      action: PayloadAction<FotosAnimalRequest>
    ): void => {
      state.isRequesting = true;
      state.errors = [];
    },
    obterFotosAnimalSuccess: (state, action: PayloadAction<Foto[]>): void => {
      state.isRequesting = false;
      state.successPayload = action.payload;
    },
    obterFotosAnimalFailed: (state, action: PayloadAction<string[]>): void => {
      state.isRequesting = false;
      state.errors = action.payload;
    },
    reset: (state): void => {
      state.successPayload = [];
      state.trechosIds = [];
    }
  }
});

function* sagaFunction(params: { type: string; payload: FotosAnimalRequest }) {
  try {
    const response: SagaReturnType<typeof api> = yield call(
      api.get,
      `api/v1/cidadao/saude/animal/${params.payload.idAnimal}/fotos`,
      {
        params: params.payload,
        paramsSerializer: (params) => transformRequestOptions(params)
      }
    );

    const { data }: { data: Foto[] } = response;

    yield put(obterFotosAnimalSuccess(data));
  } catch (error) {
    yield put(obterFotosAnimalFailed(formatError(error)));
  }
}

export const {
  obterFotosAnimal,
  obterFotosAnimalSuccess,
  obterFotosAnimalFailed,
  reset
} = obterFotosAnimalSlice.actions;
export const { reducer } = obterFotosAnimalSlice;
export const saga = takeLatest(obterFotosAnimal.type, sagaFunction);
