import { AspectRatio, Box, Image } from "@chakra-ui/react";
import { useState } from "react";
import { ArrowButton } from "./Buttons/ArrowButton";
import { DeleteImageButton } from "./Buttons/DeleteImageButton";
import { Wrapper } from "./Wrapper";
import { IndexBar } from "./IndexBar";
import { InfoArea } from "./InfoArea";
import { FullscreenButton } from "./Buttons/FullscreenButton";

type Image = {
  id: string;
  url: string;
};

type Props = {
  images: Image[];
  isDeleting?: boolean;
  onDelete?: (id: string) => void;
  onDeleteClaims?: string[];
};
export const ImageCarousel = ({
  images,
  isDeleting = false,
  onDelete
}: Props) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [current, setCurrent] = useState(0);
  const length = images.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(images) || images.length <= 0) {
    return null;
  }

  const hasButtons = !!onDelete;

  return (
    <Wrapper isFullScreen={isFullscreen} onExit={() => setIsFullscreen(false)}>
      {images.length > 1 && (
        <>
          <ArrowButton
            isFullscreen={isFullscreen}
            direction="left"
            onClick={prevSlide}
          />
          <ArrowButton
            isFullscreen={isFullscreen}
            direction="right"
            onClick={nextSlide}
          />
        </>
      )}
      <IndexBar isFullscreen={isFullscreen} length={length} index={current} />
      {images.map((image, i) => (
        <Box
          key={image.id}
          transitionDuration="1s ease"
          opacity={current == i ? 1 : 0}
        >
          {current == i && (
            <>
              <InfoArea display="none" _groupHover={{ display: "flex" }}>
                <FullscreenButton
                  isFullscreen={isFullscreen}
                  onClick={() => setIsFullscreen((state) => !state)}
                />
                {hasButtons && (
                  <DeleteImageButton
                    isFullscreen={isFullscreen}
                    onDelete={() => onDelete(image.id)}
                    isRequesting={isDeleting}
                  />
                )}
              </InfoArea>
              <AspectRatio ratio={16 / 9}>
                <Box maxH="100vh">
                  <Image
                    src={image.url}
                    w="full"
                    h="full"
                    objectFit="contain"
                  />
                </Box>
              </AspectRatio>
            </>
          )}
        </Box>
      ))}
    </Wrapper>
  );
};
