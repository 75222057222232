import { Alert, Box, Skeleton } from "@chakra-ui/react";

import { useEffect } from "react";
import { useAppDispatch, useTypedSelector } from "store/hooks";
import { obterListaAnimais } from "store/modules/zoonose/actions/obterListaAnimais";
import { ItemAnimais } from "./ItemAnimais";

export const ListaAnimais = () => {
  const dispatch = useAppDispatch();

  const { successPayload: animais, isRequesting } = useTypedSelector(
    (state) => state.zoonose.obterListaAnimais
  );
  useEffect(() => {
    dispatch(obterListaAnimais());
  }, []);

  if (animais?.length === 0) {
    return (
      <Box
        mt={5}
        p={4}
        gap={5}
        bgColor="white"
        borderRadius="md"
        boxShadow="sm"
      >
        <Alert>Nenhum animal cadastrado.</Alert>
      </Box>
    );
  }

  return (
    <Box mt={5}>
      {isRequesting && !animais ? (
        <Skeleton h="26rem" w="full" />
      ) : (
        <ItemAnimais animais={animais ?? []} />
      )}
    </Box>
  );
};
