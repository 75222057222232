/* eslint-disable react/no-children-prop */
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  useColorModeValue
} from "@chakra-ui/react";
import { forwardRef, Ref, useCallback, useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { useQueryParam } from "utils/hooks/useQueryParam";

interface InputBaseProps extends InputProps {
  size?: "sm" | "md" | "lg" | "xs";
  color?: "primary" | "secondary";
}

const InputBase = forwardRef(function InputSearchElement(
  { size = "md", color = "primary", ...rest }: InputBaseProps,
  ref: Ref<HTMLInputElement>
) {
  const bgColorSecondary = useColorModeValue("gray.50", "gray.800");
  const bgColorPrimary = useColorModeValue("gray.100", "gray.900");

  return (
    <InputGroup size={size}>
      <InputLeftElement color="gray.500" pointerEvents="none">
        <RiSearchLine />
      </InputLeftElement>
      <Input
        ref={ref}
        placeholder="Pesquisar"
        _placeholder={{ color: "gray.500" }}
        bgColor={color == "secondary" ? bgColorSecondary : bgColorPrimary}
        {...rest}
      />
    </InputGroup>
  );
});

export interface InputSearchProps extends InputBaseProps {
  name?: string;
  timer?: number;
  onChangeValue?: (n: string) => void;
}

export const InputSearch = forwardRef(function InputSearchElement(
  {
    size = "md",
    color = "primary",
    timer = 500,
    onChangeValue = () => null,
    ...rest
  }: InputSearchProps,
  ref: Ref<HTMLInputElement>
) {
  const [time, setTime] = useState<NodeJS.Timeout>(
    setTimeout(() => {
      return;
    }, 1)
  );
  const [text, setText] = useState<string>("");

  const handleChangeText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setText(value);
      clearTimeout(time);

      const func = setTimeout(() => {
        if (onChangeValue) onChangeValue(value);
      }, timer);

      setTime(func);
    },
    [time, onChangeValue]
  );

  return (
    <InputBase
      size={size}
      ref={ref}
      onInput={handleChangeText}
      color={color}
      value={text}
      {...rest}
    />
  );
});

interface InputSearchFilterProps extends InputBaseProps {
  name?: string;
  timer?: number;
  onChangeValue?: (n: string) => void;
}

export const InputSearchFilter = forwardRef(function InputSearchElement(
  {
    name = "search",
    size = "md",
    color = "primary",
    timer = 500,
    onChangeValue = () => null,
    ...rest
  }: InputSearchFilterProps,
  ref: Ref<HTMLInputElement>
) {
  const [searchParam, setSearchParam] = useQueryParam<string>(name);

  const [time, setTime] = useState<NodeJS.Timeout>(
    setTimeout(() => {
      return;
    }, 1)
  );
  const [text, setText] = useState<string>("");

  useEffect(() => {
    setText(searchParam || "");
  }, [searchParam]);

  const handleChangeText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setText(value);
      clearTimeout(time);

      const func = setTimeout(() => {
        if (onChangeValue) onChangeValue(value);
        setSearchParam(value, { replace: true });
      }, timer);

      setTime(func);
    },
    [time, onChangeValue]
  );

  return (
    <InputBase
      name={name}
      size={size}
      ref={ref}
      onInput={handleChangeText}
      color={color}
      value={text}
      {...rest}
    />
  );
});

interface InputSearchWithoutDebounceProps extends InputBaseProps {
  size?: "sm" | "md" | "lg" | "xs";
  color?: "primary" | "secondary";
  onChangeValue: (n: string) => void;
}

export const InputSearchWithoutDebounce = forwardRef(
  function InputSearchElement(
    {
      size = "md",
      color = "primary",
      onChangeValue,
      ...rest
    }: InputSearchWithoutDebounceProps,
    ref: Ref<HTMLInputElement>
  ) {
    const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      onChangeValue(value);
    };

    return (
      <InputBase
        size={size}
        ref={ref}
        onInput={handleChangeText}
        color={color}
        {...rest}
      />
    );
  }
);
