import {
  Avatar,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from "@chakra-ui/react";
import React from "react";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { RiLogoutBoxLine, RiUserLine } from "react-icons/ri";
import { useAuth } from "store/hooks/useAuth";
import { useTypedSelector } from "../../../store/hooks";

type Props = {
  showName?: boolean;
};

export const UserDescription = ({ showName }: Props) => {
  const user = useAuth();
  const logout = useSignOut();
  const { successPayload } = useTypedSelector(
    (state) => state.configuracao.obterConfiguracao
  );

  const signOut = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    logout();
    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  return (
    <Flex ml="6">
      <Menu strategy="fixed" isLazy>
        <MenuButton>
          <Flex alignItems="center">
            <Avatar
              size={"sm"}
              bg={`${successPayload?.corTema.nome}.500`}
              color="gray.50"
              name={user?.nome?.toUpperCase()}
              mr={2}
              opacity={0.6}
            />
            {showName && (
              <Text
                color={"gray.600"}
                textAlign="left"
                fontSize="sm"
                fontWeight="medium"
                maxW="140px"
                isTruncated
              >
                {user?.nome?.split(" ").slice(0, 2).join(" ")}
              </Text>
            )}
          </Flex>
        </MenuButton>
        <MenuList py="2">
          <MenuItem
            onClick={() =>
              window.open(process.env.REACT_APP_AUTHORITY, "_blank")
            }
            icon={<RiUserLine fontSize="1.1rem" />}
            py="2"
          >
            Gerenciar perfil
          </MenuItem>
          <MenuItem
            onClick={signOut}
            icon={<RiLogoutBoxLine fontSize="1.1rem" />}
            py="2"
          >
            Sair
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};
