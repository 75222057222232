import { Box, Portal } from "@chakra-ui/react";

type Props = {
  isFullScreen: boolean;
  onExit: () => void;
  children: React.ReactNode;
};
export const Wrapper = ({ isFullScreen, onExit, children }: Props) => {
  if (!isFullScreen)
    return (
      <Box
        position="relative"
        width="100%"
        maxH="100vh"
        role="group"
        margin="auto"
      >
        {children}
      </Box>
    );

  return (
    <Portal appendToParentPortal={false}>
      <Box display="flex" inset={0} position="fixed" zIndex={9999}>
        <Box
          position="relative"
          width="80vw"
          maxH="100vh"
          role="group"
          margin="auto"
        >
          {children}
        </Box>

        <Box
          onClick={onExit}
          position="fixed"
          inset={0}
          bgColor="rgba(0, 0, 0, 0.8)"
          zIndex={-1}
        ></Box>
      </Box>
    </Portal>
  );
};
