import { ChakraProvider, extendTheme, LightMode } from "@chakra-ui/react";
import { useEffect } from "react";
import AuthProvider from "react-auth-kit";
import { Provider } from "react-redux";
import { authStore } from "store/createAuthStore";
import { obterTema } from "store/modules/configuracao/actions/obterTema";
import { store } from "./store";
import { useAppDispatch, useTypedSelector } from "./store/hooks";
import { Routes } from "./_config/routes";
import "./_styles/all.css";
import { localStorageManager } from "./_styles/storageManager";
import { theme } from "./_styles/theme";

const ThemeApp = () => {
  const dispatch = useAppDispatch();
  const tema = useTypedSelector(
    (state) => state.configuracao.obterTema.successPayload
  );

  useEffect(() => {
    dispatch(obterTema());
  }, []);

  const customTheme = extendTheme({
    ...theme,
    colors: {
      ...theme.colors,
      main: tema?.cores
    }
  });

  return (
    <ChakraProvider
      resetCSS
      theme={customTheme}
      colorModeManager={localStorageManager}
    >
      <LightMode>
        <Routes />
      </LightMode>
    </ChakraProvider>
  );
};

export const App = () => {
  return (
    <Provider store={store}>
      <AuthProvider store={authStore}>
        <ThemeApp />
      </AuthProvider>
    </Provider>
  );
};
