/* eslint-disable @typescript-eslint/no-explicit-any */
import { all } from "redux-saga/effects";
import AuthSaga from "./auth/sagas";
import MapaSaga from "./mapa/sagas";
import ConfiguracaoSaga from "./configuracao/sagas";
import ZoonoseSaga from "./zoonose/sagas";
import LicenciamentoUrbanoSaga from "./licenciamentourbano/sagas";

export default function* rootSaga(): any {
  return yield all([
    AuthSaga,
    MapaSaga,
    ConfiguracaoSaga,
    ZoonoseSaga,
    LicenciamentoUrbanoSaga
  ]);
}
