import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { RiCloseLine } from "react-icons/ri";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getFullPathEnv } from "utils/pathEnv";
import api from "_config/api";
import { TextGray } from "../../../components/Atomos/Texts/TextGray";

export const CallbackPage: React.FC = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [searchParams] = useSearchParams();
  const signIn = useSignIn();

  useEffect(() => {
    const userId = searchParams.get("userId");
    const keyId = searchParams.get("keyId");
    const callbackPath = searchParams.get("callbackPath");

    async function getToken() {
      try {
        const res = await fetch(
          getFullPathEnv("REACT_APP_API_IDENTITY_URL", "/account/signin-key"),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              userId: userId,
              keyId: keyId
            })
          }
        );

        if (!res.ok) {
          setError(true);
          return;
        }

        const tokenResult = await res.json();

        const userRes = await api.post("api/v1/cidadao/identidade", null, {
          headers: {
            Authorization: `Bearer ${tokenResult.accessToken}`
          }
        });
        const { ...usuario } = userRes.data;

        const isSignedIn = signIn({
          auth: {
            token: tokenResult.accessToken,
            type: "Bearer"
          },
          refresh: tokenResult.refreshToken,
          userState: usuario
        });

        if (isSignedIn) {
          navigate(callbackPath ? callbackPath : "/");
        }
      } catch (err) {
        setError(true);
      }
    }

    getToken();
  }, [searchParams]);

  return (
    <>
      {error ? (
        <Box textAlign="center" py={10} px={6}>
          <Box display="inline-block">
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              bg={"red.500"}
              fontSize="xx-large"
              rounded="3.125rem"
              w="3.4375rem"
              h="3.4375rem"
              textAlign="center"
            >
              <RiCloseLine />
            </Flex>
          </Box>
          <Heading as="h2" size="xl" mt={6} mb={2}>
            Erro ao logar
          </Heading>
          <TextGray mb={10}>
            Não foi possivel realizar o login. Tente novamente mais tarde.
          </TextGray>
          <Button onClick={() => navigate("/")} variant="outline">
            Tentar novamente
          </Button>
        </Box>
      ) : (
        <div />
      )}
    </>
  );
};
