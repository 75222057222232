import createRefresh from "react-auth-kit/createRefresh";
import createStore from "react-auth-kit/createStore";

export type Usuario = {
  id: string;
  nome: string;
  email: string;
  perfis: string[];
  paleta: {
    nome: string;
    paleta: {
      [key: number]: string;
    };
  };
  funcionalidades: string[];
  menus: string[];
};

const my_refresh_api = createRefresh<Usuario>({
  interval: 60 * 10, // The time in sec to refresh the Access token,
  refreshApiCallback: async (param) => {
    try {
      console.log("Refreshing.....");
      const response = await fetch(
        `${process.env.REACT_APP_API_IDENTITY_URL}account/refresh-token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${param.authToken}`
          },
          body: JSON.stringify({
            refreshToken: `${param.refreshToken}`
          })
        }
      );

      const result = await response.json();

      return {
        isSuccess: true,
        newAuthToken: result.accessToken,
        newAuthTokenExpireIn: 60 * 60 * 3,
        newRefreshToken: result.refreshToken,
        newRefreshTokenExpiresIn: 60 * 60 * 20
      };
    } catch (error) {
      console.error(error);
      return {
        isSuccess: false,
        newAuthToken: ""
      };
    }
  }
});

export const authStore = createStore<Usuario>({
  authName: "_auth.sit",
  authType: "cookie",
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === "https:",
  refresh: my_refresh_api
});
