import { AspectRatio, Box, Grid, Image, Text, Icon } from "@chakra-ui/react";
import { InputDropzone } from "components/Atomos/Form/Elements/InputDropzone";
import { useEffect, useState } from "react";
import { RiCloseFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { obterListaAnimais } from "store/modules/zoonose/actions/obterListaAnimais";
import { uploadFotosAnimal } from "store/modules/zoonose/fotos/actions/uploadFotosAnimal";
import { getPreviewUploadFoto } from "utils/form-upload-foto";

interface ImageItem {
  file: File;
  previewUrl: string;
}

interface UploadFotoAnimalProps {
  idAnimal: string | null;
  onUploadSuccess?: () => void;
  onDrop?: (files: File[]) => void;
}

export const UploadFotoAnimal = ({
  idAnimal,
  onUploadSuccess,
  onDrop: onDropProp
}: UploadFotoAnimalProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [images, setImages] = useState<ImageItem[]>([]);

  async function onDrop(droppedFiles: File[]) {
    const updatedList = await Promise.all(
      droppedFiles.map(async (foto) => {
        const previewUrl = await getPreviewUploadFoto(foto);
        return { file: foto, previewUrl };
      })
    );
    setImages(updatedList);
    if (onDropProp) {
      onDropProp(droppedFiles);
    }
  }

  function removeImage(indexToRemove: number) {
    setImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  }

  const handleUpload = () => {
    if (idAnimal && images.length > 0) {
      dispatch(
        uploadFotosAnimal({
          idAnimal: idAnimal,
          fotos: images.map((img) => img.file),
          onSuccess: () => {
            setImages([]);
            navigate("/zoonose");
            dispatch(obterListaAnimais());
            if (onUploadSuccess) {
              onUploadSuccess();
            }
          }
        })
      );
    }
  };

  useEffect(() => {
    if (idAnimal && images.length > 0) {
      handleUpload();
    }
  }, [idAnimal]);

  return (
    <Box>
      {images.length > 0 && (
        <Box>
          <Grid
            w="full"
            gridTemplateColumns={{
              base: "1fr 1fr",
              md: "1fr 1fr 1fr"
            }}
            gap={4}
          >
            {images.map((img, index) => (
              <Box key={img.previewUrl} position="relative">
                <AspectRatio
                  border="1px solid black"
                  borderColor="gray.200"
                  _dark={{ borderColor: "gray.600" }}
                >
                  <Box w="full">
                    <Image
                      src={img.previewUrl}
                      w="full"
                      h="full"
                      objectFit="contain"
                    />
                  </Box>
                </AspectRatio>
                <Box
                  w="1rem"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  h="1rem"
                  borderRadius="full"
                  bgColor="black"
                  position="absolute"
                  top="-2"
                  right="-2"
                  cursor="pointer"
                  _hover={{ bgColor: "gray.600" }}
                  onClick={() => removeImage(index)}
                >
                  <Icon as={RiCloseFill} color="white" />
                </Box>
              </Box>
            ))}
          </Grid>
        </Box>
      )}

      <Box mt={3} h="5rem">
        <Text fontWeight="medium" fontSize="sm" mb={1}>
          Upload de fotos
        </Text>
        <InputDropzone
          message="Clique ou arraste a sua imagem"
          subMessage="Max: 1mb / JPG e PNG"
          onDrop={onDrop}
          accept={["image/png", "image/jpeg"]}
          multiple={true}
          maxFiles={5}
        />
      </Box>
    </Box>
  );
};
