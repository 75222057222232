/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { formatError } from "utils/errorHelper";
import api from "_config/api";

export interface ObterDetalheTipoRequerimentoRequest {
  idTipoRequerimento: string;
}

export interface DetalheTipoRequerimento {
  id: string;
  ordem?: number;
  nome: string;
  descricao: string;
  situacao: string;
  secoes: DetalheSecao[];
}

export interface DetalheSecao {
  id: string;
  ordem?: number;
  nome: string;
  questoes: DetalheQuestao[];
}

export interface DetalheQuestao {
  id: string;
  ordem?: number;
  enunciado: string;
  obrigatoria: boolean;
  tipoQuestao:
    | "short_text"
    | "large_text"
    | "numeric"
    | "date"
    | "select_list"
    | "multi_select"
    | "document"
    | "map";
  opcoes: DetalheOpcao[];
}

export interface DetalheOpcao {
  id: string;
  value?: number;
  text: string;
  tipoOpcao: "text_list" | "item_list";
  opcoes: {
    id: string;
    value?: number;
    text: string;
  }[];
}

interface State {
  isRequesting: boolean;
  successPayload: DetalheTipoRequerimento | null;
  errors: string[];
}

const initialState: State = {
  isRequesting: false,
  successPayload: null,
  errors: []
};

const obterDetalheTipoRequerimentoSlice = createSlice({
  name: "licenciamentourbano@obterDetalheTipoRequerimento",
  initialState,
  reducers: {
    obterDetalheTipoRequerimento: (
      state,
      action: PayloadAction<ObterDetalheTipoRequerimentoRequest>
    ): void => {
      state.isRequesting = true;
      state.errors = [];
    },
    obterDetalheTipoRequerimentoSuccess: (
      state,
      action: PayloadAction<DetalheTipoRequerimento>
    ): void => {
      state.isRequesting = false;
      state.successPayload = action.payload;
    },
    obterDetalheTipoRequerimentoFailed: (
      state,
      action: PayloadAction<string[]>
    ): void => {
      state.isRequesting = false;
      state.errors = action.payload;
    }
  }
});

function* sagaFunction(params: {
  type: string;
  payload: ObterDetalheTipoRequerimentoRequest;
}) {
  try {
    const response: SagaReturnType<typeof api> = yield call(
      api.get,
      `api/v1/cidadao/licenciamentourbano/${params.payload.idTipoRequerimento}`
    );

    const { data } = response;

    yield put(obterDetalheTipoRequerimentoSuccess(data));
  } catch (error) {
    yield put(obterDetalheTipoRequerimentoFailed(formatError(error)));
  }
}

export const {
  obterDetalheTipoRequerimento,
  obterDetalheTipoRequerimentoSuccess,
  obterDetalheTipoRequerimentoFailed
} = obterDetalheTipoRequerimentoSlice.actions;

export const { reducer } = obterDetalheTipoRequerimentoSlice;

export const saga = takeLatest(obterDetalheTipoRequerimento.type, sagaFunction);
