import React, { useMemo } from "react";
import { Box, BoxProps, Text } from "@chakra-ui/react";
import { FcFile } from "react-icons/fc";

interface Props extends BoxProps {
  fileName: string;
  mimeType: string;
  size?: "md" | "lg";
}

export const FileIcon: React.FC<Props> = ({
  fileName,
  mimeType,
  size = "md",
  ...props
}) => {
  const sizes = useMemo(() => {
    switch (size) {
      case "lg":
        return {
          iconSize: "2.8rem",
          typeSize: "small",
          typePadding: { x: "1.5", y: "1" },
          typeBottom: "2"
        };
      default:
        return {
          iconSize: "1.8rem",
          typeSize: "x-small",
          typePadding: { x: "1", y: "0.5" },
          typeBottom: "1"
        };
    }
  }, [size]);

  const type = useMemo(() => {
    if (!fileName) return "";
    const result = fileName.match(/\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gim);
    if (result && result.length > 0) {
      return result[0].replace(".", "");
    }
    return "";
  }, [fileName]);

  const color = useMemo(() => {
    const result = mimeType?.replace(/(.+\/)(.+)/, "$1").replace("/", "");
    switch (result) {
      case "video":
        return "red.400";
      case "image":
        return "yellow.600";
      case "application":
        return "teal.600";
      default:
        return "blue.500";
    }
  }, [mimeType]);

  return (
    <Box
      fontSize={sizes.iconSize}
      borderRadius="md"
      color="purple.400"
      position="relative"
      {...props}
    >
      <FcFile />
      {fileName && (
        <Text
          position="absolute"
          bottom={sizes.typeBottom}
          left="0"
          fontWeight="medium"
          fontSize={sizes.typeSize}
          bgColor={color}
          color="white"
          lineHeight="1"
          px={sizes.typePadding.x}
          py={sizes.typePadding.y}
          borderRadius="4"
          maxW="35px"
          isTruncated
        >
          {type}
        </Text>
      )}
    </Box>
  );
};
