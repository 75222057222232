import { Tooltip } from "@chakra-ui/react";
import { RiFullscreenExitLine, RiFullscreenLine } from "react-icons/ri";
import { ActionButton } from "./ActionButton";

type Props = {
  isFullscreen: boolean;
  onClick: () => void;
};

export const FullscreenButton = ({ isFullscreen, onClick }: Props) => {
  return (
    <Tooltip label={isFullscreen ? "Recolher" : "Expandir"} placement="top">
      <ActionButton isFullscreen={isFullscreen} onClick={onClick}>
        {isFullscreen ? <RiFullscreenExitLine /> : <RiFullscreenLine />}
      </ActionButton>
    </Tooltip>
  );
};
