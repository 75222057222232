/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, takeLeading } from "redux-saga/effects";
import { formatError } from "utils/errorHelper";
import api from "_config/api";

export interface ResponderPendenciaRequest {
  texto: string;
  files?: File[];
  idPendencia: string;
  idSolicitacao: string;
  onSuccess?: (idSolicitacao: string) => void;
}

interface State {
  isRequesting: boolean;
  errors: string[];
}

const initialState: State = {
  isRequesting: false,
  errors: []
};

const responderPendenciaSlice = createSlice({
  name: "licenciamentourbano@responderPendencia",
  initialState,
  reducers: {
    responderPendencia: (
      state,
      action: PayloadAction<ResponderPendenciaRequest>
    ): void => {
      state.isRequesting = true;
      state.errors = [];
    },
    responderPendenciaSuccess: (state): void => {
      state.isRequesting = false;
    },
    responderPendenciaFailed: (
      state,
      action: PayloadAction<string[]>
    ): void => {
      state.isRequesting = false;
      state.errors = action.payload;
    }
  }
});

function* sagaFunction(params: {
  type: string;
  payload: ResponderPendenciaRequest;
}) {
  const { onSuccess, texto, files, idPendencia, idSolicitacao } =
    params.payload;

  const formData = new FormData();
  formData.append("texto", texto);
  formData.append("idPendencia", idPendencia);
  formData.append("idSolicitacao", idSolicitacao);
  files?.forEach((f, i) => formData.append(`files[${i}]`, f as Blob));

  try {
    const response: SagaReturnType<typeof api> = yield call(
      api.put,
      `api/v1/cidadao/licenciamentourbano/pendencia`,
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );

    const { data } = response;
    if (onSuccess) onSuccess(data);

    yield put(responderPendenciaSuccess());
  } catch (error) {
    yield put(responderPendenciaFailed(formatError(error)));
  }
}

export const {
  responderPendencia,
  responderPendenciaSuccess,
  responderPendenciaFailed
} = responderPendenciaSlice.actions;

export const { reducer } = responderPendenciaSlice;
export const saga = takeLeading(responderPendencia.type, sagaFunction);
