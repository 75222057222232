import {
  Box,
  Button,
  Divider,
  Flex,
  Skeleton,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  VStack
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { BackButton } from "components/Atomos/Buttons/BackButton";
import { InputFileAnexo } from "components/Atomos/Form/Elements/InputFileAnexo";
import { InputText } from "components/Atomos/Form/Elements/InputText";
import { StatusRequerimentoTag } from "components/Atomos/Tags/StatusRequerimentoTag";
import { Title } from "components/Atomos/Texts/Title";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import {
  RiCheckboxCircleLine,
  RiCheckLine,
  RiDownloadLine
} from "react-icons/ri";
import { useParams } from "react-router-dom";
import { useAppDispatch, useTypedSelector } from "store/hooks";
import { downloadArquivoEtapaSolicitacao } from "store/modules/licenciamentourbano/actions/downloadArquivoEtapaSolicitacao";
import { downloadArquivoPendencia } from "store/modules/licenciamentourbano/actions/downloadArquivoPendencia";
import { downloadArquivoRespostaPendencia } from "store/modules/licenciamentourbano/actions/downloadArquivoRespostaPendencia";
import { downloadArquivoSolicitacao } from "store/modules/licenciamentourbano/actions/downloadArquivoSolicitacao";
import { obterDetalheSolicitacao } from "store/modules/licenciamentourbano/actions/obterDetalheSolicitacao";
import { responderPendencia } from "store/modules/licenciamentourbano/actions/responderPendencia";
import { directDownloadFileUrl } from "utils/fileHelper";
import * as yup from "yup";

export const DetalharRequerimentoInicio = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const { successPayload: solicitacao, isRequesting } = useTypedSelector(
    (state) => state.licenciamentourbano.obterDetalheSolicitacao
  );

  const { isRequesting: isRequestingDownloadPendencia } = useTypedSelector(
    (state) => state.licenciamentourbano.downloadArquivoPendencia
  );

  const { isRequesting: isRequestingDownloadRespostaPendencia } =
    useTypedSelector(
      (state) => state.licenciamentourbano.downloadArquivoRespostaPendencia
    );

  const { isRequesting: isRequestingDownloadSolicitacao } = useTypedSelector(
    (state) => state.licenciamentourbano.downloadArquivoSolicitacao
  );

  useEffect(() => {
    if (id) {
      dispatch(obterDetalheSolicitacao({ idSolicitacao: id }));
    }
  }, [id]);

  function baixarArquivoSolicitacao(idArquivo: string) {
    dispatch(
      downloadArquivoSolicitacao({
        idArquivo,
        onSuccess: (arquivo) => {
          directDownloadFileUrl(arquivo.caminho, arquivo.originalFileName);
        }
      })
    );
  }

  function baixarArquivoPendencia(idArquivo: string) {
    dispatch(
      downloadArquivoPendencia({
        idArquivo,
        onSuccess: (arquivo) => {
          directDownloadFileUrl(arquivo.caminho, arquivo.originalFileName);
        }
      })
    );
  }

  function baixarArquivoRespostaPendencia(idArquivo: string) {
    dispatch(
      downloadArquivoRespostaPendencia({
        idArquivo,
        onSuccess: (arquivo) => {
          directDownloadFileUrl(arquivo.caminho, arquivo.originalFileName);
        }
      })
    );
  }

  if (!solicitacao && !isRequesting) return <p>Solicitação não encontrada</p>;

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      bgColor="graybase.500"
      direction="column"
      overflowY="auto"
    >
      <Flex
        flex={1}
        pb="12"
        direction="column"
        maxW="container.xl"
        w="full"
        pt={{ base: 8 }}
        px={{ base: 6, sm: 8, xl: 16 }}
      >
        <BackButton to="/" />
        <Box my="6">
          <Title>Detalhes do Requerimento</Title>
        </Box>
        <Flex gap="4" direction={{ base: "column", lg: "row" }}>
          <Box flex={0.5}>
            <Box borderRadius="lg" bgColor="white" boxShadow="sm" p="6">
              <Title as="h4">Informações gerais</Title>
              {isRequesting ? (
                <Skeleton h="120px" />
              ) : (
                <VStack spacing={2} mt="2">
                  <Text w="full">
                    <Text as="strong" fontWeight="medium">
                      Protocolo:{" "}
                    </Text>
                    <Text as="span">{solicitacao?.protocolo}</Text>
                  </Text>
                  <Text w="full">
                    <Text as="strong" fontWeight="medium">
                      Tipo de Solicitação:{" "}
                    </Text>
                    <Text as="span">{solicitacao?.nomeRequerimento}</Text>
                  </Text>
                  <Text w="full">
                    <Text as="strong" fontWeight="medium">
                      Data de Envio:{" "}
                    </Text>
                    <Text as="span">{solicitacao?.dataEnvioFormatada}</Text>
                  </Text>
                  <Text w="full">
                    <Text as="strong" fontWeight="medium">
                      Status Atual:{" "}
                    </Text>
                    {solicitacao?.status && (
                      <StatusRequerimentoTag
                        nome={solicitacao?.status?.name}
                        id={solicitacao?.status?.value?.toString()}
                      />
                    )}
                  </Text>
                  <Text w="full">
                    <Text as="strong" fontWeight="medium">
                      Última Atualização:{" "}
                    </Text>
                    <Text as="span">
                      {solicitacao?.dataUltimaAtualizacaoFormatada}
                    </Text>
                  </Text>
                </VStack>
              )}
            </Box>
            <Box borderRadius="xl" bgColor="white" boxShadow="sm" p="6" mt="4">
              <Text fontSize="1.2rem" fontWeight="medium" mb="4">
                Etapas do processo
              </Text>
              {solicitacao?.etapas.map((e, i) => (
                <EtapaProcesso
                  key={e.idEtapa}
                  etapa={e.ordem}
                  status={
                    e.isConcluida
                      ? "concluido"
                      : e.isAtual
                      ? "atual"
                      : "pendente"
                  }
                  titulo={e.nome}
                  data={e.dataConclusaoFormatada}
                  equipe={e.equipe}
                  last={i === solicitacao?.etapas.length - 1}
                  observacao={e.observacao}
                  arquivos={e.arquivosConclusao}
                  situacaoEtapa={e.situacaoEtapaSolicitacao}
                />
              ))}
            </Box>
          </Box>
          <Box borderRadius="lg" bgColor="white" boxShadow="sm" p="6" flex={1}>
            {solicitacao?.pendencias && solicitacao.pendencias.length > 0 && (
              <Box
                bgColor={
                  solicitacao?.pendencias?.some((x) => !x.isResolvida)
                    ? "red.100"
                    : "gray.100"
                }
                py="4"
                px="6"
                mb="5"
                borderRadius="lg"
              >
                <Text fontWeight="medium" fontSize="lg" mb="2">
                  Pendências ou Observações
                </Text>
                {solicitacao?.pendencias.map((pendencia) => (
                  <Flex
                    key={pendencia.id}
                    borderRadius="lg"
                    bgColor="white"
                    px="4"
                    py="2"
                  >
                    <Box flex={1}>
                      <Flex pt="2">
                        <Text w="full">
                          <Text as="strong" fontWeight="medium">
                            Status:{" "}
                          </Text>
                          <Text as="span">{pendencia.status}</Text>
                        </Text>
                        {pendencia.isResolvida && (
                          <Text
                            display="inline-flex"
                            alignItems="center"
                            gap="1"
                            fontSize="sm"
                          >
                            <RiCheckboxCircleLine size={18} />
                            Resolvido
                          </Text>
                        )}
                      </Flex>
                      <Text w="full" mb="1">
                        <Text as="strong" fontWeight="medium">
                          Observação:{" "}
                        </Text>
                        <Text as="span">{pendencia.comentario}</Text>
                      </Text>

                      {pendencia.arquivos?.map((f) => (
                        <Button
                          key={f.id}
                          variant="solid"
                          colorScheme="gray"
                          fontSize="sm"
                          py="1"
                          w="full"
                          justifyContent="start"
                          size="sm"
                          onClick={() => baixarArquivoPendencia(f.id)}
                          isLoading={isRequestingDownloadPendencia.includes(
                            f.id
                          )}
                        >
                          <RiDownloadLine size={16} />
                          <Text ml="1" as="span">
                            {f.originalFileName}
                          </Text>
                        </Button>
                      ))}
                      <Divider my="4" />
                      {pendencia.respostas.map((resposta) => (
                        <Flex
                          key={
                            (resposta.dataCriacao || "") +
                            (resposta.comentario || "")
                          }
                          direction="column"
                          alignItems={
                            resposta.isColaborador ? "flex-start" : "end"
                          }
                          mb="4"
                        >
                          <Text
                            bgColor={
                              resposta.isColaborador ? "gray.500" : "green.600"
                            }
                            py="2"
                            px="4"
                            borderRadius="lg"
                            color="white"
                          >
                            {resposta.comentario}
                          </Text>
                          {resposta.arquivos?.map((f) => (
                            <Button
                              key={f.id}
                              variant="link"
                              fontSize="sm"
                              py="1"
                              onClick={() =>
                                baixarArquivoRespostaPendencia(f.id)
                              }
                              isLoading={isRequestingDownloadRespostaPendencia.includes(
                                f.id
                              )}
                            >
                              <RiDownloadLine size={16} />
                              <Text ml="1" as="span">
                                {f.originalFileName}
                              </Text>
                            </Button>
                          ))}
                          <Text fontSize="sm">
                            <Text as="span" fontWeight="medium">
                              {resposta.responsavel}
                            </Text>{" "}
                            <Text as="span" fontSize="xs" color="gray.600">
                              {resposta.dataCriacaoFormatada}
                            </Text>
                          </Text>
                        </Flex>
                      ))}
                      {!pendencia.isResolvida && id && (
                        <Box mt="2" w="full">
                          <FormPendencia
                            idPendencia={pendencia.id}
                            idSolicitacao={id}
                          />
                        </Box>
                      )}
                    </Box>
                  </Flex>
                ))}
              </Box>
            )}
            <Title as="h4">Informações fornecidas</Title>
            {isRequesting ? (
              <Skeleton h="40px" />
            ) : (
              <Box mt="2">
                <Text fontWeight="medium" fontSize="xl">
                  Informações do projeto
                </Text>
                <VStack spacing={2} mt="2">
                  {solicitacao?.informacoesRequerimentos.map((i) => (
                    <Text key={i.enunciado} w="full">
                      <Text as="strong" fontWeight="medium">
                        {i.enunciado}:{" "}
                      </Text>
                      <Text as="span">{i.resposta}</Text>
                    </Text>
                  ))}
                </VStack>
              </Box>
            )}
            <Divider my="5" />
            {solicitacao?.arquivos && solicitacao?.arquivos.length > 0 && (
              <Box>
                <Text fontWeight="medium" fontSize="xl" mb="2">
                  Documentação anexada
                </Text>
                <TableContainer>
                  <Table variant="simple" size="sm">
                    <Thead>
                      <Tr>
                        <Th w="full">Nome do arquivo</Th>
                        <Th>Visualizar</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {solicitacao?.arquivos.map((a) => (
                        <Tr key={a.id}>
                          <Td w="full">{a.originalFileName}</Td>
                          <Td textAlign="center">
                            <Button
                              size="sm"
                              variant="ghost"
                              onClick={() => baixarArquivoSolicitacao(a.id)}
                              isLoading={isRequestingDownloadSolicitacao.includes(
                                a.id
                              )}
                            >
                              <RiDownloadLine size={18} />
                            </Button>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

const schema = yup.object({
  texto: yup
    .string()
    .required("Campo obrigatório")
    .max(500, "O texto deve ter no máximo 500 caracteres"),
  files: yup.array().of(yup.mixed().required("O arquivo é obrigatório"))
});

function FormPendencia({
  idSolicitacao,
  idPendencia
}: {
  idSolicitacao: string;
  idPendencia: string;
}) {
  const methods = useForm<{ [key: string]: any }>({
    resolver: yupResolver(schema)
  });
  const toast = useToast();
  const [isAnswering, setIsAnswering] = useState(false);
  const dispatch = useAppDispatch();

  const { isRequesting } = useTypedSelector(
    (state) => state.licenciamentourbano.responderPendencia
  );

  const onSubmit: SubmitHandler<any> = (data) => {
    dispatch(
      responderPendencia({
        texto: data.texto,
        files: data.files,
        idSolicitacao: idSolicitacao,
        idPendencia,
        onSuccess: () => {
          toast({
            title: "Pendência respondida com sucesso",
            status: "success",
            duration: 2000,
            isClosable: true
          });
          methods.reset();
          setIsAnswering(false);
          dispatch(obterDetalheSolicitacao({ idSolicitacao: idSolicitacao }));
        }
      })
    );
  };

  if (!isAnswering) {
    return (
      <Flex w="full" justifyContent="end">
        <Button
          colorScheme="main"
          size="sm"
          onClick={() => setIsAnswering(true)}
        >
          Responder
        </Button>
      </Flex>
    );
  }

  return (
    <FormProvider {...methods}>
      <Box as="form" onSubmit={methods.handleSubmit(onSubmit)} w="full" gap={5}>
        <VStack mb="4">
          <InputText
            label="Mensagem"
            name="texto"
            register={methods.register}
            error={methods.formState.errors["texto"]}
          />
          <InputFileAnexo
            name="files"
            label="Arquivos"
            error={methods.formState.errors["files"]}
          />
        </VStack>
        <Flex w="full" justifyContent="end" gap="2">
          <Button
            variant="solid"
            colorScheme="gray"
            size="sm"
            onClick={() => setIsAnswering(false)}
          >
            Cancelar
          </Button>
          <Button
            colorScheme="main"
            type="submit"
            size="sm"
            isLoading={isRequesting}
          >
            Enviar
          </Button>
        </Flex>
      </Box>
    </FormProvider>
  );
}

function EtapaProcesso({
  etapa,
  titulo,
  data,
  status,
  equipe,
  observacao,
  arquivos,
  situacaoEtapa,
  last = false
}: {
  etapa: number;
  titulo: string;
  data?: string | null;
  equipe?: string;
  observacao?: string;
  status: "concluido" | "atual" | "pendente";
  last?: boolean;
  arquivos?: {
    id: string;
    idResposta: string;
    nome: string;
    originalFileName?: string;
    idSolicitacaoEtapa?: string;
  }[];
  situacaoEtapa?: {
    value: number;
    name: string;
  };
}) {
  const dispatch = useAppDispatch();

  const { isRequesting: isDownloading } = useTypedSelector(
    (state) => state.licenciamentourbano.downloadArquivoEtapaSolicitacao
  );

  function baixarArquivoEtapaSolicitacao(idArquivo: string) {
    dispatch(
      downloadArquivoEtapaSolicitacao({
        idArquivo,
        onSuccess: (arquivo) => {
          directDownloadFileUrl(arquivo.caminho, arquivo.originalFileName);
        }
      })
    );
  }

  const currentOrDone = status == "concluido" || status == "atual";

  return (
    <Box
      pl="8"
      ml="4"
      minH={100}
      pb="6"
      borderLeft="3px"
      borderLeftStyle={!last ? "solid" : undefined}
      borderLeftColor={status == "concluido" ? "green.500" : "gray.300"}
      position="relative"
      alignItems="start"
    >
      <Flex
        position="absolute"
        left={0}
        top={0}
        transform="translateX(-50%)"
        bgColor={currentOrDone ? "green.500" : "gray.200"}
        textColor={currentOrDone ? "white" : "gray.800"}
        h="12"
        w="12"
        borderColor="white"
        borderWidth="6px"
        borderStyle="solid"
        alignItems="center"
        justifyContent="center"
        borderRadius="full"
      >
        {status == "concluido" ? (
          <Text fontSize="xl">
            <RiCheckLine />
          </Text>
        ) : (
          etapa.toString().padStart(2, "0")
        )}
      </Flex>

      {situacaoEtapa && (
        <Box mb="1">
          <StatusRequerimentoTag
            nome={situacaoEtapa.name}
            id={situacaoEtapa.value?.toString()}
            size="sm"
          />
        </Box>
      )}
      <Text
        fontSize="xs"
        fontWeight="semibold"
        color={status == "atual" ? "green.500" : "gray.600"}
        casing="uppercase"
      >
        Etapa {etapa} {status == "concluido" && "- Concluído"}
        {status == "atual" && "- Atual"}
      </Text>

      <Text fontWeight="medium">{titulo}</Text>
      {data && <Text fontSize="sm">Data: {data}</Text>}
      <VStack spacing={1} alignItems="start" mt="0.5">
        {equipe && <Tag variant="outline">{equipe}</Tag>}
        {observacao && (
          <Text fontSize="sm">
            <Text as="span" fontWeight="semibold">
              Obs:{" "}
            </Text>
            {observacao}
          </Text>
        )}
        {arquivos &&
          arquivos.map((f) => (
            <Button
              key={f.id}
              variant="solid"
              colorScheme="gray"
              fontSize="sm"
              py="1"
              w="full"
              justifyContent="start"
              size="sm"
              onClick={() => baixarArquivoEtapaSolicitacao(f.id)}
              isLoading={isDownloading.includes(f.id)}
            >
              <RiDownloadLine size={16} />
              <Text ml="1" as="span">
                {f.originalFileName}
              </Text>
            </Button>
          ))}
      </VStack>
    </Box>
  );
}
