/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLeading } from "redux-saga/effects";
import { formatError } from "utils/errorHelper";
import api from "_config/api";

export interface AnimalRequest {
  id: string;
  onSuccess?: () => void;
}

interface State {
  isRequesting: boolean;
  errors: string[];
}

const initialState: State = {
  isRequesting: false,
  errors: []
};

const excluirAnimalSlice = createSlice({
  name: "zoonose@excluirAnimal",
  initialState,
  reducers: {
    excluirAnimal: (state, action: PayloadAction<AnimalRequest>): void => {
      state.isRequesting = true;
      state.errors = [];
    },
    excluirAnimalSuccess: (state): void => {
      state.isRequesting = false;
    },
    excluirAnimalFailed: (state, action: PayloadAction<string[]>): void => {
      state.isRequesting = false;
      state.errors = action.payload;
    }
  }
});

function* sagaFunction(params: { type: string; payload: AnimalRequest }) {
  const { id, onSuccess } = params.payload;
  try {
    yield call(api.delete, `api/v1/cidadao/saude/animal/${id}`);

    if (onSuccess) onSuccess();
    yield put(excluirAnimalSuccess());
  } catch (error) {
    yield put(excluirAnimalFailed(formatError(error)));
  }
}

export const { excluirAnimal, excluirAnimalSuccess, excluirAnimalFailed } =
  excluirAnimalSlice.actions;

export const { reducer } = excluirAnimalSlice;
export const saga = takeLeading(excluirAnimal.type, sagaFunction);
