import { all } from "redux-saga/effects";

import { saga as obterDetalheTipoRequerimento } from "./actions/obterDetalheTipoRequerimento";
import { saga as obterTiposRequerimentos } from "./actions/obterTiposRequerimentos";
import { saga as cadastrarSolicitacao } from "./actions/cadastrarSolicitacao";
import { saga as obterDetalheSolicitacao } from "./actions/obterDetalheSolicitacao";
import { saga as obterMinhasSolicitacoes } from "./actions/obterMinhasSolicitacoes";
import { saga as obterUltimasSolicitacoes } from "./actions/obterUltimasSolicitacoes";
import { saga as downloadArquivoPendencia } from "./actions/downloadArquivoPendencia";
import { saga as downloadArquivoSolicitacao } from "./actions/downloadArquivoSolicitacao";
import { saga as responderPendencia } from "./actions/responderPendencia";
import { saga as downloadArquivoRespostaPendencia } from "./actions/downloadArquivoRespostaPendencia";
import { saga as downloadArquivoEtapaSolicitacao } from "./actions/downloadArquivoEtapaSolicitacao";

export default all([
  obterDetalheTipoRequerimento,
  obterTiposRequerimentos,
  cadastrarSolicitacao,
  obterDetalheSolicitacao,
  obterMinhasSolicitacoes,
  obterUltimasSolicitacoes,
  downloadArquivoPendencia,
  downloadArquivoSolicitacao,
  responderPendencia,
  downloadArquivoRespostaPendencia,
  downloadArquivoEtapaSolicitacao
]);
