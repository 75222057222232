import {
  Box,
  Button,
  Flex,
  Heading,
  useColorModeValue
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { TextGray } from "../../../components/Atomos/Texts/TextGray";

export const AcessoNegado: React.FC = () => {
  const navigate = useNavigate();
  const goHome = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    navigate("/");
  };

  return (
    <Box
      flex="1"
      h="full"
      bgGradient={useColorModeValue(
        "linear(to-br, white 0%, gray.200 25%, white 75%)",
        "linear(to-br, gray.900 0%, gray.800 25%, gray.900 75%)"
      )}
    >
      <Flex
        maxH="25rem"
        w="full"
        textAlign="center"
        direction="column"
        alignItems="center"
        py={10}
        px={8}
      >
        <Heading as="h2" textAlign="center" size="xl" mt={10} mb={4}>
          Você não possui permissão para acessar o sistema.
        </Heading>
        <TextGray>
          Contate o administrador do sistema e verifique seu cadastro.
        </TextGray>
        <Button colorScheme="blue" mt={20} onClick={goHome}>
          Voltar para o início
        </Button>
      </Flex>
    </Box>
  );
};
