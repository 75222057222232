import React, { ReactNode } from "react";
import { Box, Text } from "@chakra-ui/react";

type Props = {
  children: ReactNode;
  as?: "h1" | "h2" | "h3" | "h4" | "h5";
};

export const Title: React.FC<Props> = ({ as = "h3", children }) => {
  return (
    <Box pb="2">
      <Text as={as} fontWeight="medium">
        {children}
      </Text>
    </Box>
  );
};
