import { AspectRatio, Box, Center, Flex, Text } from "@chakra-ui/react";
import { TextGray } from "components/Atomos/Texts/TextGray";
import { ImageCarousel } from "components/Moleculas/ImageCarousel";
import {
  ObterAnimaisResponse,
  obterListaAnimais
} from "store/modules/zoonose/actions/obterListaAnimais";
import { HeaderItemAnimais } from "./HeaderItemAnimais";
import dayjs from "dayjs";
import { useAppDispatch, useTypedSelector } from "store/hooks";
import { removerFotoAnimal } from "store/modules/zoonose/fotos/actions/removerFotoAnimal";

interface ItemAnimaisProps {
  animais: ObterAnimaisResponse[];
}

export const ItemAnimais = ({ animais }: ItemAnimaisProps) => {
  const dispatch = useAppDispatch();

  const { isRequesting } = useTypedSelector(
    (state) => state.zoonose.fotos.removerFotoAnimal
  );

  const removerImagem = (idAnimal: string, idArquivo: string) => {
    if (idAnimal) {
      dispatch(
        removerFotoAnimal({
          idArquivo,
          idAnimal: idAnimal,
          onSuccess: () => {
            dispatch(obterListaAnimais());
          }
        })
      );
    }
  };

  return (
    <Flex direction="column" gap={4}>
      {animais?.map((animal) => (
        <Flex
          direction={{ base: "column", md: "row" }}
          key={animal.id}
          p={4}
          gap={5}
          bgColor="white"
          borderRadius="md"
          boxShadow="sm"
        >
          <Box flex={1}>
            {animal.arquivos && animal.arquivos.length > 0 ? (
              <Box w={{ base: "full", md: "25rem" }}>
                <ImageCarousel
                  images={animal.arquivos.map((x) => ({
                    id: x.id,
                    url: x.fileUrl
                  }))}
                  onDelete={(idArquivo) => removerImagem(animal.id, idArquivo)}
                  isDeleting={isRequesting}
                />
              </Box>
            ) : (
              <Box w={{ base: "full", md: "25rem" }}>
                <AspectRatio ratio={16 / 9}>
                  <Center bgColor="gray.100">
                    <TextGray fontWeight="medium" fontSize="x-large">
                      Sem fotos
                    </TextGray>
                  </Center>
                </AspectRatio>
              </Box>
            )}
            <Text mt={2} fontSize="2xl" fontWeight="bold">
              {animal?.nome}
            </Text>
          </Box>
          <Box flex={2} bgColor="gray.200" p={2} borderRadius="md">
            <HeaderItemAnimais idAnimal={animal.id} nomeAnimal={animal.nome} />
            <Text>
              Espécie:{" "}
              <Text as="span" fontWeight="bold">
                {animal?.especie?.name ?? "-"}
              </Text>
            </Text>
            <Text>
              Raça:{" "}
              <Text as="span" fontWeight="bold">
                {animal?.raca == "" ? "-" : animal.raca}
              </Text>
            </Text>
            <Text>
              Sexo:{" "}
              <Text as="span" fontWeight="bold">
                {animal?.sexo?.name ?? "-"}
              </Text>
            </Text>
            <Text>
              Porte:{" "}
              <Text as="span" fontWeight="bold">
                {animal?.porte?.name ?? "-"}
              </Text>
            </Text>
            <Text>
              Tipo de pelagem:{" "}
              <Text as="span" fontWeight="bold">
                {animal?.pelagem == "" ? "-" : animal.pelagem}
              </Text>
            </Text>
            <Text>
              Peso (kg):{" "}
              <Text as="span" fontWeight="bold">
                {animal.peso ?? "-"}
              </Text>
            </Text>
            <Text>
              Cor predominante (kg):{" "}
              <Text as="span" fontWeight="bold">
                {animal.corPredominante == "" ? "-" : animal.corPredominante}
              </Text>
            </Text>
            <Text>
              Doenças preexistentes:{" "}
              <Text as="span" fontWeight="bold">
                {animal?.doencasPreexistentes?.map((doenca) => (
                  <Text key={doenca.value} as="span" fontWeight="bold">
                    {doenca.name}
                    {", "}
                  </Text>
                ))}
              </Text>
            </Text>
            <Text>
              Animal castrado:{" "}
              <Text as="span" fontWeight="bold">
                {animal.castrado ? "Sim" : "Não"}
              </Text>
            </Text>
            {!animal.castrado && (
              <Text>
                Deseja castração:{" "}
                <Text as="span" fontWeight="bold">
                  {animal.desejaCastrar ? "Sim" : "Não"}
                </Text>
              </Text>
            )}
            <Text>
              Vacina antirrábica em dia?{" "}
              <Text as="span" fontWeight="bold">
                {animal.vacinaEmDia ? "Sim" : "Não"}
              </Text>
            </Text>
            <Text>
              Data da última vacinação{" "}
              <Text as="span" fontWeight="bold">
                {dayjs(animal.dataUltimaVacinacao).format("DD/MM/YYYY") ?? "-"}
              </Text>
            </Text>
            <Text>
              Idade (anos):{" "}
              <Text as="span" fontWeight="bold">
                {animal.idadeAnos ?? "-"}
              </Text>
            </Text>
            <Text>
              Idade (meses):{" "}
              <Text as="span" fontWeight="bold">
                {animal.idadeMeses ?? "-"}
              </Text>
            </Text>
            <Text>
              Data óbito:{" "}
              <Text as="span" fontWeight="bold">
                {animal.dataObito ?? "Não se aplica"}
              </Text>
            </Text>
          </Box>
        </Flex>
      ))}
    </Flex>
  );
};
