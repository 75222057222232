import { Box, Flex } from "@chakra-ui/react";
import useEmblaCarousel from "embla-carousel-react";
import { ReactNode } from "react";
import { NextButton, PrevButton, usePrevNextButtons } from "./ArrowButtons";
import "./embla.css";

export const Carousel = ({
  title,
  size = 70,
  spacing = 1,
  items
}: {
  title?: ReactNode;
  size?: number;
  spacing?: number;
  items: ReactNode[];
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({});

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi);

  return (
    <Box
      className="embla"
      sx={{
        "--slide-spacing": `${spacing}rem`,
        "--slide-size": `${size}%`
      }}
    >
      <Flex
        justifyContent={title ? "space-between" : "end"}
        alignItems="center"
        mb="2"
      >
        {title}
        <Flex>
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </Flex>
      </Flex>
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {items.map((item, index) => (
            <div className="embla__slide" key={index}>
              <div className="embla__slide__number">
                <Box bgColor="white" h="full" w="full">
                  {item}
                </Box>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Box>
  );
};
