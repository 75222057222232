import { CustomInputMultiSelect } from "components/Atomos/Form/CustomInputMultiSlect";
import { useEffect } from "react";
import { Control, FieldError } from "react-hook-form";
import { useAppDispatch, useTypedSelector } from "store/hooks";
import { obterDoencas } from "store/modules/zoonose/actions/obterDoencas";

interface InputSelectDoencasProps {
  error?: FieldError;
  control: Control<any, object>;
}

export const InputSelectDoencas = ({
  control,
  error
}: InputSelectDoencasProps) => {
  const dispatch = useAppDispatch();

  const { successPayload: doencas, isRequesting } = useTypedSelector(
    (state) => state.zoonose.obterDoencas
  );

  useEffect(() => {
    dispatch(obterDoencas());
  }, []);

  return (
    <CustomInputMultiSelect
      label="Doenças preexistentes"
      name="doencasPreexistentes"
      control={control}
      error={error}
      isMulti
      isDisabled={isRequesting}
      placeholder={isRequesting ? "Carregando" : "Selecione"}
      option={doencas.map((x) => ({
        value: String(x.value),
        label: x.name
      }))}
    />
  );
};
