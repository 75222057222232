/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-children-prop */
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  SelectProps
} from "@chakra-ui/react";
import { Control, Controller, FieldError } from "react-hook-form";
import { IconType } from "react-icons";
import { TextGray } from "../../Texts/TextGray";

interface Options {
  value: string;
  label: string;
}

interface Props extends Omit<SelectProps, "icon"> {
  name: string;
  label?: string;
  icon?: IconType;
  error?: FieldError;
  isLoading?: boolean;
  isDisabled?: boolean;
  hasDefaultValue?: boolean;
  placeholder?: string;
  option: Options[];
  control: Control<any, object>;
}

export const InputSelect = ({
  name,
  label,
  error,
  isLoading = false,
  isDisabled = false,
  hasDefaultValue = true,
  placeholder = "Selecione um valor",
  icon: IconComponent,
  control,
  option,
  ...props
}: Props) => {
  return (
    <FormControl isInvalid={!!error}>
      {!!label && (
        <FormLabel htmlFor={name} fontSize="sm">
          <Flex alignItems="center">
            {IconComponent && (
              <TextGray as="span" mr="1.5">
                <IconComponent />
              </TextGray>
            )}
            {label}
          </Flex>
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            {...props}
            id={name}
            disabled={isLoading || isDisabled}
          >
            {hasDefaultValue && <option value="">{placeholder}</option>}
            {option.map((x) => (
              <option key={x.value} value={x.value}>
                {x.label}
              </option>
            ))}
          </Select>
        )}
      />
      {!!error && (
        <FormErrorMessage mt="1" fontSize="xs">
          {error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
