import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect
} from "react";
import {
  Flex,
  FormControl,
  FormLabel,
  Text,
  useColorModeValue,
  FormErrorMessage,
  Button,
  Box
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { DeepMap, FieldError, useFormContext } from "react-hook-form";
import { IconType } from "react-icons";

import { Arquivo } from "./Arquivo";
import { TextGray } from "components/Atomos/Texts/TextGray";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name: string;
  tag?: string;
  label?: string;
  icon?: IconType;
  accept?: string;
  filesUploading?: string[];
  error?: FieldError;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    name,
    label,
    error,
    icon: IconComponent,
    accept,
    filesUploading = [],
    ...props
  },
  ref
) => {
  const containerColor = useColorModeValue("gray.100", "gray.700");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const { register, unregister, setValue, watch } = useFormContext();
  const files: File[] = watch(name);

  const onDrop = useCallback(
    (droppedFiles: File[]) => {
      const newFiles: File[] = [];
      droppedFiles.forEach((e) => {
        if (!files || files.find((f) => f.name == e.name) == null) {
          newFiles.push(e);
        }
      });
      const values = [...(files || []), ...newFiles];
      setValue(name, values, {
        shouldValidate: false
      });
    },
    [setValue, name, files]
  );

  const remove = useCallback(
    (fileName: string) => {
      setValue(
        name,
        files.filter((f) => f.name !== fileName),
        {
          shouldValidate: false
        }
      );
    },
    [setValue, name, files]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accept
  });

  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  return (
    <FormControl isInvalid={!!error} w="full">
      {!!label && (
        <FormLabel htmlFor={name}>
          <Flex alignItems="center">
            {IconComponent && (
              <TextGray as="span" mr="1.5">
                <IconComponent />
              </TextGray>
            )}
            {label}
          </Flex>
        </FormLabel>
      )}
      <Box>
        <Flex
          bgColor={containerColor}
          borderWidth="2px"
          borderStyle="dashed"
          borderRadius="lg"
          p="4"
          py="6"
          justifyContent="center"
          alignItems="center"
          direction="column"
          {...getRootProps()}
          borderColor={error ? "#E53E3E" : borderColor}
          boxShadow={error ? "0 0 0 1px #e53e3e" : ""}
        >
          <Text fontSize="medium" fontWeight="medium">
            Selecione o(s) arquivo(s)
          </Text>
          <input ref={ref} {...props} {...getInputProps()} />

          <TextGray mt="6" textAlign="center" fontSize="sm">
            PDF e DOCx / Máx. 5MB
          </TextGray>
        </Flex>
      </Box>
      {!!error && (
        <FormErrorMessage mt="0.5" fontSize="xs">
          {error.message as string}
        </FormErrorMessage>
      )}
      {!!files &&
        files.map((x) => (
          <Arquivo
            key={x.name}
            file={x}
            onRemove={remove}
            isUploading={filesUploading.filter((f) => f == x.name).length > 0}
          />
        ))}
    </FormControl>
  );
};

export const InputFileAnexo = forwardRef(InputBase);
