import { combineReducers } from "redux";

import fotos from "./fotos/reducer";

import { reducer as obterListaAnimais } from "./actions/obterListaAnimais";
import { reducer as obterDetalheAnimal } from "./actions/obterDetalheAnimal";
import { reducer as cadastrarAnimal } from "./actions/cadastrarAnimal";
import { reducer as obterSexoAnimal } from "./actions/obterSexoAnimal";
import { reducer as obterDoencas } from "./actions/obterDoencas";
import { reducer as obterPorteAnimal } from "./actions/obterPorteAnimal";
import { reducer as obterEspecieAnimal } from "./actions/obterEspecieAnimal";
import { reducer as editarAnimal } from "./actions/editarAnimal";
import { reducer as excluirAnimal } from "./actions/excluirAnimal";
import { reducer as cadastrarTutor } from "./actions/cadastrarTutor";

export const zoonose = combineReducers({
  fotos,

  obterListaAnimais,
  obterDetalheAnimal,
  cadastrarAnimal,
  obterSexoAnimal,
  obterDoencas,
  obterPorteAnimal,
  obterEspecieAnimal,
  editarAnimal,
  excluirAnimal,
  cadastrarTutor
});
