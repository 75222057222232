import { Box, Text } from "@chakra-ui/react";
import { Title } from "components/Atomos/Texts/Title";
import { ServicosList } from "components/Organismos/ServicosList";

export const ServicosDisponiveis = () => {
  return (
    <Box maxW="1200px" mx="auto" pt="20">
      <Title>Serviços</Title>
      <ServicosList
        card={{ bgColor: "white" }}
        title={
          <Text mb={2} lineHeight="1.2">
            Confira abaixo a lista de serviços disponíveis
          </Text>
        }
      />
    </Box>
  );
};
