import { Button, HStack, Text, useToast } from "@chakra-ui/react";
import { DeleteConfirmation } from "components/Moleculas/Confirmations/DeleteConfirmation";
import { RiDeleteBin2Line, RiEdit2Fill } from "react-icons/ri";
import { useAppDispatch, useTypedSelector } from "store/hooks";
import { excluirAnimal } from "store/modules/zoonose/actions/excluirAnimal";
import { obterListaAnimais } from "store/modules/zoonose/actions/obterListaAnimais";

interface HeaderItemAnimaisProps {
  nomeAnimal: string;
  idAnimal: string;
}

export const HeaderItemAnimais = ({
  nomeAnimal,
  idAnimal
}: HeaderItemAnimaisProps) => {
  const dispatch = useAppDispatch();
  const toast = useToast();

  const { isRequesting: loadingDelete } = useTypedSelector(
    (state) => state.zoonose.excluirAnimal
  );

  const reload = () => {
    dispatch(obterListaAnimais());
  };

  const handleDelete = () => {
    if (idAnimal) {
      dispatch(
        excluirAnimal({
          id: idAnimal,
          onSuccess: () => {
            toast({
              title: "Animal excluído com sucesso",
              status: "success",
              position: "bottom"
            });
            reload();
          }
        })
      );
    }
  };

  return (
    <HStack justifyContent="space-between">
      <Text>
        Nome:{" "}
        <Text as="span" fontWeight="bold">
          {nomeAnimal}
        </Text>
      </Text>
      <HStack>
        <DeleteConfirmation
          message={<Text>Deseja realmente seguir com a exclusão?</Text>}
          buttonRemoveMessage="Excluir"
          onConfirm={handleDelete}
          buttonTrigger={
            <Button
              leftIcon={<RiDeleteBin2Line />}
              size="sm"
              variant="ghost"
              colorScheme="red"
              isLoading={loadingDelete}
            >
              Excluir
            </Button>
          }
        />
        <Button
          leftIcon={<RiEdit2Fill />}
          size="sm"
          variant="ghost"
          colorScheme="blue"
        >
          Editar
        </Button>
      </HStack>
    </HStack>
  );
};
