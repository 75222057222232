import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  useColorModeValue
} from "@chakra-ui/react";
import { TextGray } from "components/Atomos/Texts/TextGray";
import { Control, Controller, FieldError } from "react-hook-form";
import { IconType } from "react-icons";
import Select from "react-select";
import { createColourStyles } from "./styles";

interface Options {
  value: string;
  label: string;
}

interface Props {
  name: string;
  label?: string;
  icon?: IconType;
  error?: FieldError;
  isLoading?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  option: Options[];
  control: Control<any, object>;
  isMulti?: boolean;
}

export const CustomInputMultiSelect = ({
  name,
  label,
  error,
  isLoading = false,
  isDisabled = false,
  placeholder = "Selecione um valor",
  icon: IconComponent,
  control,
  option,
  isMulti = false
}: Props) => {
  const bgColor = useColorModeValue("#f7fafc", "#171923");
  const bgOption = useColorModeValue("#f7fafc", "#2e3748");
  const isColor = useColorModeValue(true, false);

  const styles = createColourStyles(bgColor, bgOption, isColor);

  return (
    <FormControl isInvalid={!!error}>
      {!!label && (
        <FormLabel htmlFor={name} fontSize="sm">
          <Flex alignItems="center">
            {IconComponent && (
              <TextGray as="span" mr="1.5">
                <IconComponent />
              </TextGray>
            )}
            {label}
          </Flex>
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            options={option}
            isMulti={isMulti}
            placeholder={placeholder}
            isDisabled={isLoading || isDisabled}
            styles={styles}
            onChange={(selected: any) =>
              field.onChange(
                isMulti
                  ? selected
                    ? selected.map((item: any) => item.value)
                    : []
                  : selected
                  ? selected.value
                  : null
              )
            }
            value={
              isMulti
                ? option.filter(
                    (opt) =>
                      Array.isArray(field.value) &&
                      field.value.includes(opt.value)
                  )
                : option.find((opt) => opt.value === field.value)
            }
          />
        )}
      />
      {!!error && (
        <FormErrorMessage mt="1" fontSize="xs">
          {error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
