/* eslint-disable @typescript-eslint/no-explicit-any */
export const transformRequestOptions = (params: {
  [key: string]: any;
}): string => {
  let options = "";
  for (const key in params) {
    if (typeof params[key] !== "object") {
      options += `${key}=${params[key]}&`;
    } else if (
      typeof params[key] === "object" &&
      params[key] &&
      params[key].length
    ) {
      params[key].forEach((el: any) => {
        options += `${key}=${el}&`;
      });
    }
  }
  return options ? options.slice(0, -1) : options;
};
