export function dataURItoBlob(dataURI: string): Blob {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);
  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

export function dataURItoFile(name: string, dataURI: string): File {
  const blob = dataURItoBlob(dataURI);
  return new File([blob], name, {
    lastModified: new Date().getTime(),
    type: blob.type
  });
}

export function directDownloadFile(blob: Blob, nomeArquivo = "arquivo") {
  const href = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", nomeArquivo);
  link.click();
}

export function directDownloadFileUrl(url: string, nomeArquivo = "arquivo") {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", nomeArquivo);
  link.setAttribute("target", "_blank");
  link.click();
}
