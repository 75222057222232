import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tooltip
} from "@chakra-ui/react";
import React, { useState } from "react";

interface Props {
  message: React.ReactNode | string;
  buttonTrigger: React.ReactNode;
  buttonRemoveMessage?: string;
  tooltipMessage?: string;
  onConfirm: () => void;
}

export const DeleteConfirmation: React.FC<Props> = ({
  message,
  buttonTrigger,
  buttonRemoveMessage = "Remover",
  tooltipMessage,
  onConfirm
}) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  return (
    <Popover placement="right">
      {({ onClose }) => (
        <>
          <Tooltip
            label={tooltipMessage}
            placement="top"
            isOpen={tooltipIsOpen}
            isDisabled={!tooltipMessage}
          >
            <Box
              display="inline-block"
              onMouseEnter={() => setTooltipIsOpen(true)}
              onMouseLeave={() => setTooltipIsOpen(false)}
            >
              <PopoverTrigger>{buttonTrigger}</PopoverTrigger>
            </Box>
          </Tooltip>
          <PopoverContent>
            <PopoverArrow />
            <PopoverHeader fontSize="sm">
              <Flex pr="6">{message}</Flex>
              <Flex mt="2">
                <Button
                  colorScheme="red"
                  size="xs"
                  onClick={() => {
                    onConfirm();
                    onClose();
                  }}
                >
                  {buttonRemoveMessage}
                </Button>
              </Flex>
            </PopoverHeader>
            <PopoverCloseButton />
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
