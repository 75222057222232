import { HStack, HTMLChakraProps } from "@chakra-ui/react";

type Props = {
  children: React.ReactNode;
} & HTMLChakraProps<"div">;

export const InfoArea = ({ children, ...props }: Props) => {
  return (
    <HStack
      align="flex-start"
      position="absolute"
      top="0.3125rem"
      left="0.3125rem"
      zIndex={10}
      {...props}
    >
      {children}
    </HStack>
  );
};
