import { Text } from "@chakra-ui/react";
import { DeleteConfirmation } from "components/Moleculas/Confirmations/DeleteConfirmation";

import { RiDeleteBin2Line } from "react-icons/ri";
import { ActionButton } from "./ActionButton";

type Props = {
  isRequesting: boolean;
  onDelete: () => void;
  isFullscreen: boolean;
};

export const DeleteImageButton = ({
  isRequesting,
  isFullscreen,
  onDelete
}: Props) => {
  return (
    <DeleteConfirmation
      buttonTrigger={
        <ActionButton isFullscreen={isFullscreen} isRequesting={isRequesting}>
          <RiDeleteBin2Line />
        </ActionButton>
      }
      tooltipMessage="Remover foto"
      message={<Text>Deseja realmente remover esta imagem?</Text>}
      onConfirm={onDelete}
    />
  );
};
