import { Box, Text, Button, Flex } from "@chakra-ui/react";
import { StatusRequerimentoTag } from "components/Atomos/Tags/StatusRequerimentoTag";
import React from "react";
import { RiCalendar2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

export const RequerimentoCard: React.FC<{
  id: string;
  title: string;
  data: string;
  protocolo: string;
  status?: {
    value: number;
    name: string;
  };
}> = ({ id, title, data, protocolo, status }) => {
  const navigate = useNavigate();
  return (
    <Box>
      <Flex mb="1" gap="2">
        <Flex
          borderStyle="solid"
          borderWidth={1}
          borderColor="gray.400"
          borderRadius="md"
          px="2"
          fontSize="xs"
          alignItems="center"
        >
          Protocolo: {protocolo}
        </Flex>
        {status && (
          <StatusRequerimentoTag
            id={status.value.toString()}
            nome={status.name}
          />
        )}
      </Flex>
      <Text fontWeight="medium">{title}</Text>
      <Flex alignItems="center" gap={1} fontSize="sm" mt="0.5">
        <RiCalendar2Line /> Data de solicitação:{" "}
        <Text as="span" fontWeight="medium">
          {data}
        </Text>
      </Flex>
      <Button
        variant="mainOutline"
        size="sm"
        mt="2"
        onClick={() => navigate(`/licenciamento-urbano/requerimento/${id}`)}
      >
        Acompanhar solicitação
      </Button>
    </Box>
  );
};
