import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { BackButton } from "components/Atomos/Buttons/BackButton";
import { ServicosList } from "components/Organismos/ServicosList";
import { Header } from "layouts/LayoutDefault/Header";
import React from "react";
import { getFullPathEnv } from "utils/pathEnv";

export const Servicos: React.FC = () => {
  function redirectLogin(callbackUrl: string) {
    window.location.href = `${getFullPathEnv(
      "REACT_APP_IDENTITY_URL",
      `login?callbackUrl=${callbackUrl}`
    )}`;
  }

  return (
    <Flex h="full" direction="column">
      <Header />
      <Flex
        flex={1}
        pt="20"
        direction="column"
        justifyContent="space-between"
        bgColor="#f8f8f8"
      >
        <Flex
          direction={{ base: "column", lg: "row" }}
          maxW="1200px"
          mx="auto"
          px="8"
          gap={12}
        >
          <Box flex={0.6}>
            <BackButton to="/login" />
            <Text
              mt="4"
              fontWeight="medium"
              fontSize="2.5rem"
              mb="10"
              lineHeight="1.1"
            >
              Descubra todos os serviços disponíveis para você
            </Text>
            <Text fontSize="xl" lineHeight="1.2" mb="6">
              Aqui você encontra serviços essenciais para o seu dia a dia, tudo
              em um só lugar. Realize solicitações e acompanhe processos com
              rapidez e praticidade.
            </Text>
            <Text fontSize="xl" fontWeight="medium" lineHeight="1.2">
              Confira os serviços disponíveis para acessar após realizar o
              login.
            </Text>
          </Box>
          <Box flex={1}>
            <ServicosList
              container={{ bgColor: "white", boxShadow: "sm", p: "8" }}
              card={{ bgColor: "gray.100" }}
              title={
                <Text fontSize="xl" fontWeight="medium" mb={2} lineHeight="1.2">
                  Explore os serviços da sua cidade
                </Text>
              }
            />
            <Flex
              mt="4"
              borderRadius="lg"
              p="8"
              bgColor="main.500"
              boxShadow="sm"
              justifyContent="space-between"
              alignItems={{ base: "start", md: "center" }}
              direction={{ base: "column", md: "row" }}
            >
              <Box>
                <Text color="white" fontSize="lg" fontWeight="medium">
                  Não encontrou o que precisa?
                </Text>
                <Text color="white">
                  Faça login para acessar mais serviços personalizados.
                </Text>
              </Box>
              <Button
                mt={{ base: "4", md: "0" }}
                bgColor="white"
                color="main.500"
                onClick={() => redirectLogin(`${window.location.origin}/`)}
              >
                Acessar minha conta
              </Button>
            </Flex>
          </Box>
        </Flex>
        <Box w="full" bgColor="white" fontSize="sm" textAlign="center" py="2">
          Sistema de Informações Territoriais - EGL Engenharia
        </Box>
      </Flex>
    </Flex>
  );
};
