/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { formatError } from "utils/errorHelper";
import { transformRequestOptions } from "utils/queryString";
import api from "_config/api";

export interface ObterDetalheAnimalRequest {
  idAnimal: string;
}

interface Option {
  value: number;
  name: string;
}

interface Animal {
  idAnimal: string;
  nome: string;
  pelagem: string;
  peso: number;
  tipoPorte: Option;
  raca: string;
  tipoSexo: Option;
  castrado: boolean;
  corPredominante: string;
  dataObito: string;
  dataUltimaVacinacao: string;
  desejaCastrar: boolean;
  doencaPreexistente: string;
  tipoEspecie: Option;
  idadeAnos: number;
  idadeMeses: number;
  vacinaEmDia: boolean;
}

interface Responsavel {
  id: string;
  nome: string;
  cpf: string;
  rg: string;
  bairro: string;
  celular: string;
  cep: string;
  cns: string;
  complemento: string;
  dataNascimento: string;
  email: string;
  logradouro: string;
  municipio: string;
  numero: string;
  participaProgramaSocial: boolean;
  quadra: string;
  telefoneContato: string;
  uf: string;
  isPrincipal: boolean;
}

export interface ObterDetalheAnimalResponse {
  animal: Animal;
  responsaveis: Responsavel[];
}

interface State {
  isRequesting: boolean;
  lastRequest: ObterDetalheAnimalRequest | null;
  successPayload: ObterDetalheAnimalResponse | null;
  errors: string[];
}

const initialState: State = {
  isRequesting: false,
  lastRequest: null,
  successPayload: null,
  errors: []
};

const obterDetalheAnimalSlice = createSlice({
  name: "zoonose@obterDetalheAnimal",
  initialState,
  reducers: {
    obterDetalheAnimal: (
      state,
      action: PayloadAction<ObterDetalheAnimalRequest>
    ): void => {
      state.isRequesting = true;
      state.errors = [];
      state.lastRequest = {
        ...action.payload
      };
    },
    obterDetalheAnimalSuccess: (
      state,
      action: PayloadAction<ObterDetalheAnimalResponse>
    ): void => {
      state.isRequesting = false;
      state.successPayload = action.payload;
    },
    obterDetalheAnimalFailed: (
      state,
      action: PayloadAction<string[]>
    ): void => {
      state.isRequesting = false;
      state.errors = action.payload;
    }
  }
});

function* sagaFunction(params: {
  type: string;
  payload: ObterDetalheAnimalRequest;
}) {
  try {
    const response: SagaReturnType<typeof api> = yield call(
      api.get,
      `api/v1/cidadao/saude/animais/detalhes`,
      {
        params: params.payload,
        paramsSerializer: (params) => transformRequestOptions(params)
      }
    );

    const { data } = response;

    yield put(obterDetalheAnimalSuccess(data));
  } catch (error) {
    yield put(obterDetalheAnimalFailed(formatError(error)));
  }
}

export const {
  obterDetalheAnimal,
  obterDetalheAnimalSuccess,
  obterDetalheAnimalFailed
} = obterDetalheAnimalSlice.actions;

export const { reducer } = obterDetalheAnimalSlice;

export const saga = takeLatest(obterDetalheAnimal.type, sagaFunction);
