import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { IoArrowForward, IoPawOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

export const MenuList = () => {
  const menuServicos = [
    {
      titulo: "Meus animais",
      icon: IoPawOutline,
      to: "/zoonose"
    }
  ];

  return (
    <>
      {menuServicos.map((s) => (
        <Link key={s.titulo} to={s.to}>
          <Box
            p={3}
            borderRadius="md"
            bgColor="white"
            boxShadow="sm"
            w="fit-content"
            cursor="pointer"
            _hover={{ bgColor: "gray.50" }}
          >
            <HStack justifyContent="space-between" alignItems="flex-start">
              <VStack
                bgColor="gray.100"
                borderRadius="full"
                w="2.5rem"
                h="2.5rem"
                p={2}
              >
                <s.icon color="#e59560" size={20} />
              </VStack>
              <IoArrowForward color="#e59560" size={15} />
            </HStack>
            <Text fontSize="sm">{s.titulo}</Text>
          </Box>
        </Link>
      ))}
    </>
  );
};
