/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { formatError } from "utils/errorHelper";
import api from "_config/api";

export interface DownloadArquivoEtapaSolicitacaoRequest {
  idArquivo: string;
  onSuccess: (arquivo: ArquivoEtapaSolicitacao) => void;
}

export interface ArquivoEtapaSolicitacao {
  id: string;
  caminho: string;
  fileName: string;
  originalFileName: string;
}

interface State {
  isRequesting: string[];
  errors: string[];
}

const initialState: State = {
  isRequesting: [],
  errors: []
};

const downloadArquivoEtapaSolicitacaoSlice = createSlice({
  name: "licenciamentourbano@downloadArquivoEtapaSolicitacao",
  initialState,
  reducers: {
    downloadArquivoEtapaSolicitacao: (
      state,
      action: PayloadAction<DownloadArquivoEtapaSolicitacaoRequest>
    ): void => {
      state.isRequesting = [...state.isRequesting, action.payload.idArquivo];
      state.errors = [];
    },
    downloadArquivoEtapaSolicitacaoSuccess: (
      state,
      action: PayloadAction<ArquivoEtapaSolicitacao>
    ): void => {
      state.isRequesting = state.isRequesting.filter(
        (x) => x != action.payload.id
      );
    },
    downloadArquivoEtapaSolicitacaoFailed: (
      state,
      action: PayloadAction<{ id: string; error: string[] }>
    ): void => {
      state.isRequesting = state.isRequesting.filter(
        (x) => x != action.payload.id
      );
    }
  }
});

function* sagaFunction(params: {
  type: string;
  payload: DownloadArquivoEtapaSolicitacaoRequest;
}) {
  const { idArquivo, onSuccess } = params.payload;
  try {
    const response: SagaReturnType<typeof api> = yield call(
      api.get,
      `api/v1/cidadao/licenciamentourbano/arquivo-resposta-solicitacao-etapa/${idArquivo}`
    );

    const { data } = response;
    onSuccess(data);

    yield put(downloadArquivoEtapaSolicitacaoSuccess(data));
  } catch (error) {
    yield put(
      downloadArquivoEtapaSolicitacaoFailed({
        id: params.payload.idArquivo,
        error: formatError(error)
      })
    );
  }
}

export const {
  downloadArquivoEtapaSolicitacao,
  downloadArquivoEtapaSolicitacaoSuccess,
  downloadArquivoEtapaSolicitacaoFailed
} = downloadArquivoEtapaSolicitacaoSlice.actions;

export const { reducer } = downloadArquivoEtapaSolicitacaoSlice;

export const saga = takeLatest(
  downloadArquivoEtapaSolicitacao.type,
  sagaFunction
);
