import { Button } from "@chakra-ui/react";
import { RiArrowLeftLine } from "react-icons/ri";
import { Link } from "react-router-dom";

type Props = {
  to: string;
};

export const BackButton = ({ to }: Props) => {
  return (
    <Link to={to}>
      <Button colorScheme="gray" color="gray.900" variant="link" gap={2}>
        <RiArrowLeftLine /> Voltar
      </Button>
    </Link>
  );
};
