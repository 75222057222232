import { Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useAuth } from "store/hooks/useAuth";
import { Logo } from "../../components/Logo";
import { UserDescription } from "../../components/UserDescription";

export const Header = () => {
  const user = useAuth();

  return (
    <Flex
      as="nav"
      px="8"
      position="sticky"
      zIndex="1"
      top="0"
      w="full"
      bgColor="white"
      borderBottom="1px solid rgb(0 0 0 / 7%)"
      minH="60px"
    >
      <Flex maxW="1200px" w="full" margin="0 auto">
        <Flex w="100%" alignItems="center">
          <Logo />
          <Flex>
            {user && (
              <>
                <Flex
                  as={Link}
                  to="/"
                  ml="1"
                  h="full"
                  px="4"
                  align="center"
                  _hover={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                >
                  Início
                </Flex>
                <Flex
                  as={Link}
                  to="/servicos-disponiveis"
                  ml="1"
                  h="full"
                  px="4"
                  align="center"
                  _hover={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                >
                  Serviços
                </Flex>
                <Flex
                  as={Link}
                  to="/licenciamento-urbano/meus-requerimentos"
                  ml="1"
                  h="full"
                  px="4"
                  align="center"
                  _hover={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                >
                  Meus requerimentos
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
        <Flex w={{ lg: "100%" }} alignItems="center" justifyContent="flex-end">
          {user && <UserDescription />}
        </Flex>
      </Flex>
    </Flex>
  );
};
