import { Image, useColorModeValue } from "@chakra-ui/react";
import { getFullPathEnv } from "utils/pathEnv";

export const Logo = ({ maxW = "75px" }: { maxW?: string }) => {
  const logo = useColorModeValue(
    getFullPathEnv("REACT_APP_API", `api/v1/Customizacao/logo/light`),
    getFullPathEnv("REACT_APP_API", `api/v1/Customizacao/logo/dark`)
  );

  return <Image src={logo} alt="logo sit" maxW={maxW} />;
};
