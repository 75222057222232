import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { Usuario } from "store/createAuthStore";
import { useTypedSelector } from ".";

export const useAuth = () => useAuthUser<Usuario>();

export function isInAllRole(perfis: string[]): boolean {
  const { usuario } = useTypedSelector((state) => state.auth.obterUsuario);

  if (!usuario) return false;

  let result = true;
  perfis.forEach((x) => {
    if (!usuario.perfis.includes(x)) result = false;
  });
  return result;
}

export function isInOneRole(perfis: string[]): boolean {
  const { usuario } = useTypedSelector((state) => state.auth.obterUsuario);

  if (!usuario) return false;

  let result = false;
  perfis.forEach((x) => {
    if (usuario.perfis.includes(x)) result = true;
  });
  return result;
}

export function isInOneClaim(claims: string[]): boolean {
  const { usuario } = useTypedSelector((state) => state.auth.obterUsuario);

  if (!usuario) return false;

  let result = false;
  claims.forEach((x) => {
    if (usuario.funcionalidades.includes(x)) result = true;
  });

  return result;
}
