/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { formatError } from "utils/errorHelper";
import api from "_config/api";

export interface DownloadArquivoRespostaPendenciaRequest {
  idArquivo: string;
  onSuccess: (arquivo: ArquivoPendencia) => void;
}

export interface ArquivoPendencia {
  id: string;
  caminho: string;
  fileName: string;
  originalFileName: string;
}

interface State {
  isRequesting: string[];
  errors: string[];
}

const initialState: State = {
  isRequesting: [],
  errors: []
};

const downloadArquivoRespostaPendenciaSlice = createSlice({
  name: "licenciamentourbano@downloadArquivoRespostaPendencia",
  initialState,
  reducers: {
    downloadArquivoRespostaPendencia: (
      state,
      action: PayloadAction<DownloadArquivoRespostaPendenciaRequest>
    ): void => {
      state.isRequesting = [...state.isRequesting, action.payload.idArquivo];
      state.errors = [];
    },
    downloadArquivoRespostaPendenciaSuccess: (
      state,
      action: PayloadAction<ArquivoPendencia>
    ): void => {
      state.isRequesting = state.isRequesting.filter(
        (x) => x != action.payload.id
      );
    },
    downloadArquivoRespostaPendenciaFailed: (
      state,
      action: PayloadAction<{ id: string; error: string[] }>
    ): void => {
      state.isRequesting = state.isRequesting.filter(
        (x) => x != action.payload.id
      );
    }
  }
});

function* sagaFunction(params: {
  type: string;
  payload: DownloadArquivoRespostaPendenciaRequest;
}) {
  const { idArquivo, onSuccess } = params.payload;
  try {
    const response: SagaReturnType<typeof api> = yield call(
      api.get,
      `api/v1/cidadao/licenciamentourbano/arquivo-resposta-pendencia/${idArquivo}`
    );

    const { data } = response;
    onSuccess(data);

    yield put(downloadArquivoRespostaPendenciaSuccess(data));
  } catch (error) {
    yield put(
      downloadArquivoRespostaPendenciaFailed({
        id: params.payload.idArquivo,
        error: formatError(error)
      })
    );
  }
}

export const {
  downloadArquivoRespostaPendencia,
  downloadArquivoRespostaPendenciaSuccess,
  downloadArquivoRespostaPendenciaFailed
} = downloadArquivoRespostaPendenciaSlice.actions;

export const { reducer } = downloadArquivoRespostaPendenciaSlice;

export const saga = takeLatest(
  downloadArquivoRespostaPendencia.type,
  sagaFunction
);
