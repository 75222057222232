import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

export const LayoutBody = () => {
  return (
    <Box overflowY="auto" flex="1" bgColor="graybase.500">
      <Outlet />
    </Box>
  );
};
