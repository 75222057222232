/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-children-prop */

import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  TextareaProps
} from "@chakra-ui/react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { IconType } from "react-icons";
import { TextGray } from "../../Texts/TextGray";

interface Props extends TextareaProps {
  name: string;
  label?: string;
  icon?: IconType;
  error?: FieldError;
  register: UseFormRegister<any>;
}

export const InputTextArea = ({
  name,
  label,
  error,
  register,
  icon: IconComponent,
  ...props
}: Props) => {
  return (
    <FormControl isInvalid={!!error}>
      {!!label && (
        <FormLabel htmlFor={name} fontSize="sm">
          <Flex alignItems="center">
            {IconComponent && (
              <TextGray as="span" mr="1.5">
                <IconComponent />
              </TextGray>
            )}
            {label}
          </Flex>
        </FormLabel>
      )}
      <Textarea id={name} {...register(name)} {...props} />
      {!!error && (
        <FormErrorMessage mt="1" fontSize="xs">
          {error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
