import {
  Box,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  IconButton,
  Progress,
  Text,
  Tooltip,
  useColorModeValue,
  HStack
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { RiCloseLine } from "react-icons/ri";
import { TextGray } from "components/Atomos/Texts/TextGray";
import { FileIcon } from "components/Atomos/Icons/FileIcon";

interface Props {
  file: File;
  onRemove: (e: string) => void;
  isUploading: boolean;
  error?: string;
}

export const Arquivo: React.FC<Props> = ({
  file,
  isUploading,
  onRemove,
  error
}) => {
  const colorIcon = useColorModeValue("gray.500", "gray.400");
  const colorBorder = useColorModeValue("gray.100", "gray.700");

  function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return (
      parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +
      " " +
      sizes[i]
    ).replace(".", ",");
  }

  return (
    <Box
      px="2"
      py="1"
      my="2"
      borderRadius="md"
      borderWidth="1px"
      borderStyle="solid"
      borderColor={colorBorder}
    >
      <Flex>
        <Flex alignItems="center" flex={1} flexDir="column">
          <HStack w="full">
            <FileIcon fileName={file.name} mimeType={file.type} />
            <Box p="1" mx="1" lineHeight="1.2" flex={1}>
              <Text fontSize="sm">
                {file?.name?.split(".").slice(0, -1).join(".")}
              </Text>
              <TextGray fontSize="xs">{formatBytes(file.size)}</TextGray>
            </Box>
          </HStack>
        </Flex>
        <Tooltip label="Remover" placement="top">
          <IconButton
            color={colorIcon}
            size="sm"
            aria-label="remover"
            variant="ghost"
            icon={<RiCloseLine />}
            onClick={() => onRemove(file.name)}
          />
        </Tooltip>
      </Flex>
      {isUploading && (
        <Flex direction="column">
          <Text fontSize="x-small" textAlign="right">
            Carregando...
          </Text>
          <Progress size="xs" w="full" isIndeterminate />
        </Flex>
      )}
      {error && (
        <Text fontSize="x-small" color="red.500">
          {error}
        </Text>
      )}
    </Box>
  );
};
