import { Button, Flex, HStack, useToast } from "@chakra-ui/react";
import { InputCheckbox } from "components/Atomos/Form/Elements/InputCheckbox";
import { InputFileAnexo } from "components/Atomos/Form/Elements/InputFileAnexo";
import { InputMap } from "components/Atomos/Form/Elements/InputMap";
import { InputNumber } from "components/Atomos/Form/Elements/InputNumber";
import { InputSelect } from "components/Atomos/Form/Elements/InputSelect";
import { InputText } from "components/Atomos/Form/Elements/InputText";
import { InputTextArea } from "components/Atomos/Form/Elements/InputTextArea";
import { useMemo } from "react";
import * as yup from "yup";
import {
  Control,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext
} from "react-hook-form";
import { useAppDispatch } from "store/hooks";
import { cadastrarSolicitacao } from "store/modules/licenciamentourbano/actions/cadastrarSolicitacao";
import { DetalheQuestao } from "store/modules/licenciamentourbano/actions/obterDetalheTipoRequerimento";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

const generateValidationSchema = (
  fieldsConfig: { name: string; required: boolean }[]
) => {
  const schemaShape = fieldsConfig.reduce((shape, field) => {
    shape[field.name] = field.required
      ? yup.mixed().test("is-valid-type", "O campo é obrigatório", (value) => {
          if (typeof value === "string" && value !== "") return true;
          if (Array.isArray(value)) return value.length > 0;
          if (typeof value === "object") return value !== null;
          return false;
        })
      : yup.string().nullable();
    return shape;
  }, {} as { [key: string]: yup.AnySchema });

  return yup.object().shape(schemaShape);
};

export const FormRequerimento = ({
  idTipoRequerimento,
  questoes
}: {
  idTipoRequerimento: string;
  questoes: DetalheQuestao[];
}) => {
  const navigate = useNavigate();
  const schema = generateValidationSchema(
    questoes.map((q) => ({ name: q.id, required: q.obrigatoria }))
  );

  const dispatch = useAppDispatch();
  const toast = useToast();

  const methods = useForm<{ [key: string]: any }>({
    resolver: yupResolver(schema)
  });

  const onSubmit: SubmitHandler<any> = (data) => {
    const formData = new FormData();

    formData.append(`TipoRequerimento`, idTipoRequerimento);

    let index = -1;
    Object.entries(data).forEach(([key, value]) => {
      if (!value) return;
      index++;

      formData.append(`RespostasSolicitacao[${index}].IdQuestao`, key);
      if (Array.isArray(value) && value.every((v) => v instanceof File)) {
        value.forEach((file, fIndex) => {
          formData.append(
            `RespostasSolicitacao[${index}].Files[${fIndex}]`,
            file as Blob
          );
        });

        return;
      }

      if (Array.isArray(value)) {
        value.forEach((v, vIndex) => {
          formData.append(
            `RespostasSolicitacao[${index}].Values[${vIndex}]`,
            v
          );
        });
        return;
      }

      if (typeof value === "object" && "lat" in value && "lng" in value) {
        const coordinates = value as { lat: number; lng: number };
        formData.append(
          `RespostasSolicitacao[${index}].Coordinates.Lat`,
          coordinates.lat.toString()
        );
        formData.append(
          `RespostasSolicitacao[${index}].Coordinates.Lng`,
          coordinates.lng.toString()
        );
        return;
      }

      formData.append(`RespostasSolicitacao[${index}].Value`, value as string);
    });

    if (Array.from(formData.entries()).length > 0) {
      dispatch(
        cadastrarSolicitacao({
          respostas: formData,
          onSuccess: () => {
            toast({
              title: "Solicitação realizada com sucesso",
              status: "success",
              duration: 2000,
              isClosable: true
            });
            navigate("/");
          }
        })
      );
    }
  };

  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        onSubmit={methods.handleSubmit(onSubmit)}
        w="full"
        direction="column"
        gap={5}
      >
        <FormFields questoes={questoes} />

        <HStack w="full" justifyContent="flex-end" mt={5}>
          <Button variant="outline" mr={3} onClick={() => navigate("/")}>
            Cancelar
          </Button>
          <Button colorScheme="main" type="submit">
            Concluir solicitação
          </Button>
        </HStack>
      </Flex>
    </FormProvider>
  );
};

export const FormFields = ({ questoes }: { questoes: DetalheQuestao[] }) => {
  const {
    control,
    register,
    formState: { errors }
  } = useFormContext();

  const questoesFields = useMemo(() => {
    return questoes.map((q) => {
      if (q.tipoQuestao === "short_text") {
        return (
          <InputText
            key={q.id}
            label={q.enunciado}
            name={q.id}
            register={register}
            error={errors[q.id]}
          />
        );
      }
      if (q.tipoQuestao === "large_text") {
        return (
          <InputTextArea
            key={q.id}
            label={q.enunciado}
            name={q.id}
            register={register}
            error={errors[q.id]}
          />
        );
      }
      if (q.tipoQuestao === "numeric") {
        return (
          <InputNumber
            key={q.id}
            label={q.enunciado}
            name={q.id}
            min={0}
            control={control}
            error={errors[q.id]}
          />
        );
      }
      if (q.tipoQuestao === "date") {
        return (
          <InputText
            key={q.id}
            label={q.enunciado}
            name={q.id}
            type="date"
            register={register}
            error={errors[q.id]}
          />
        );
      }
      if (q.tipoQuestao === "map") {
        return (
          <InputMap
            key={q.id}
            label={q.enunciado}
            name={q.id}
            control={control}
            error={errors[q.id]}
          />
        );
      }
      if (q.tipoQuestao === "select_list") {
        return (
          <SelectListSuboptions key={q.id} control={control} questao={q} />
        );
      }
      if (q.tipoQuestao === "document") {
        return (
          <InputFileAnexo
            key={q.id}
            name={q.id}
            label={q.enunciado}
            error={errors[q.id]}
          />
        );
      }
      if (q.tipoQuestao === "multi_select") {
        return (
          <InputCheckbox
            key={q.id}
            control={control}
            label={q.enunciado}
            name={q.id}
            option={q.opcoes.map((o) => ({ value: o.id, label: o.text }))}
            error={errors[q.id]}
          />
        );
      }

      return null;
    });
  }, [questoes, errors]);

  return <>{questoesFields}</>;
};

function SelectListSuboptions({
  control,
  questao
}: {
  control: Control<any, object>;
  questao: DetalheQuestao;
}) {
  const {
    watch,
    register,
    formState: { errors }
  } = useFormContext();
  const opcaoSelecionada = watch(questao.id);

  const opcao = questao.opcoes.find((o) => o.id === opcaoSelecionada);

  return (
    <>
      <InputSelect
        control={control}
        label={questao.enunciado}
        name={questao.id}
        option={questao.opcoes.map((o) => ({ value: o.id, label: o.text }))}
        error={errors[questao.id]}
      />

      {opcao?.opcoes &&
        opcao.opcoes.length > 0 &&
        opcao.tipoOpcao == "item_list" && (
          <InputSelect
            control={control}
            label={opcao.text}
            name={opcao.id}
            option={opcao.opcoes.map((o) => ({ value: o.id, label: o.text }))}
          />
        )}

      {opcao?.opcoes && opcao.tipoOpcao == "text_list" && (
        <InputText label={opcao.text} name={opcao.id} register={register} />
      )}
    </>
  );
}
