/* eslint-disable @typescript-eslint/no-explicit-any */
import { cssVar, extendTheme } from "@chakra-ui/react";
import { SystemStyleObject } from "@chakra-ui/system";
import { GlobalStyleProps, mode, transparentize } from "@chakra-ui/theme-tools";
import { config } from "./config";

export const theme = extendTheme({
  config,
  colors: {
    // https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/foundations/colors.ts
    main: {
      50: "#F7FAFC",
      100: "#EDF2F7",
      200: "#E2E8F0",
      300: "#CBD5E0",
      400: "#A0AEC0",
      500: "#718096",
      600: "#4A5568",
      700: "#2D3748",
      800: "#1A202C",
      900: "#171923"
    },
    graybase: {
      500: "#f8f8f8"
    }
  },
  fonts: {
    heading: "Roboto",
    body: "Roboto"
  },
  components: {
    Alert: {
      baseStyle: () => ({
        container: {
          borderRadius: "md",
          px: "8",
          fontWeight: "medium"
        }
      })
    },
    Popover: {
      variants: {
        responsive: {
          popper: {
            maxWidth: "unset",
            width: "unset"
          }
        }
      },
      baseStyle: (props: any) => ({
        arrow: {
          bg: props.colorMode === "dark" ? "gray.900" : "white"
        },
        content: {
          [cssVar("popper-arrow-bg").variable]:
            props.colorMode === "dark" ? "gray.900" : "white",
          bg: props.colorMode === "dark" ? "gray.900" : "white",
          border: "1px solid rgb(0 0 0 / 6%)",
          fontSize: "0.9rem",
          borderColor: props.colorMode === "dark" ? "gray.900" : "gray.100"
        }
      })
    },
    Checkbox: {
      baseStyle: (props: any) => ({
        control: {
          borderColor: mode(`gray.400`, `gray.600`)(props),
          _checked: {
            bg: mode(`main.500`, `main.200`)(props),
            borderColor: mode(`main.500`, `main.200`)(props),
            color: mode("white", "gray.900")(props),
            _hover: {
              bg: mode(`main.600`, `main.300`)(props),
              borderColor: mode(`main.600`, `main.300`)(props)
            }
          },
          _indeterminate: {
            bg: mode(`main.500`, `main.200`)(props),
            borderColor: mode(`main.500`, `main.200`)(props)
          }
        }
      })
    },
    Radio: {
      baseStyle: (props: any) => ({
        control: {
          borderColor: mode(`gray.400`, `gray.600`)(props),
          _checked: {
            bg: mode(`main.500`, `main.200`)(props),
            borderColor: mode(`main.500`, `main.200`)(props),
            color: mode("white", "gray.900")(props),
            _hover: {
              bg: mode(`main.600`, `main.300`)(props),
              borderColor: mode(`main.600`, `main.300`)(props)
            }
          },
          _indeterminate: {
            bg: mode(`main.500`, `main.200`)(props),
            borderColor: mode(`main.500`, `main.200`)(props)
          }
        }
      })
    },
    Switch: {
      baseStyle: (props: any) => ({
        track: {
          _checked: {
            bg: mode(`main.500`, `main.400`)(props)
          }
        }
      })
    },
    Tooltip: {
      variants: {
        black: {
          bg: "gray.700",
          color: "whiteAlpha.900"
        }
      }
    },
    Tag: {
      baseStyle: {
        label: {
          lineHeight: 1
        }
      },
      variants: {
        main: (props: any) => ({
          ...theme.components.Tag.variants.subtle(props),
          container: {
            bg: mode(`main.100`, transparentize(`main.200`, 0.16))(props),
            color: mode(`main.800`, `main.200`)(props)
          }
        })
      }
    },
    Button: {
      baseStyle: {
        lineHeight: 1
      },
      variants: {
        link: (props: any) => ({
          color: props.colorMode === "dark" ? "blue.400" : "blue.600",
          _focus: {
            boxShadow: "none"
          }
        }),
        main: (props: any) => ({
          ...theme.components.Button.variants.solid(props),
          color: "white",
          bgColor: props.colorMode === "dark" ? "main.600" : "main.400",
          fontWeight: "medium",
          _hover: {
            bgColor: props.colorMode === "dark" ? "main.700" : "main.500",
            _disabled: {
              bgColor: props.colorMode === "dark" ? "main.500" : "main.400"
            }
          },
          _active: {
            filter: "brightness(1.05)"
          }
        }),
        mainOutline: (props: any) => ({
          ...theme.components.Button.variants.outline(props),
          color: mode(`main.500`, `main.300`)(props),
          borderColor: mode(`main.500`, `main.300`)(props),
          fontWeight: "medium",
          _hover: {
            color: "white",
            bgColor: mode(`main.500`, `main.600`)(props),
            borderColor: mode(`main.500`, `main.600`)(props)
          }
        }),
        ghost: {
          fontWeight: "medium"
        }
      }
    },
    IconButton: {
      ghost: (props: any) => ({
        color: props.colorMode === "dark" ? "gray.500" : "gray.400"
      })
    },
    Input: {
      variants: {
        outline: (props: any) => ({
          field: {
            bg: props.colorMode === "dark" ? "gray.900" : "gray.50",
            borderColor:
              props.colorMode === "dark" ? "rgba(0,0,0,0.15)" : "inherit"
          }
        })
      }
    },
    Select: {
      variants: {
        outline: (props: any) => ({
          field: {
            bg: props.colorMode === "dark" ? "gray.900" : "gray.50",
            borderColor:
              props.colorMode === "dark" ? "rgba(0,0,0,0.15)" : "inherit"
          }
        })
      }
    },
    Textarea: {
      variants: {
        outline: (props: any) => ({
          bg: props.colorMode === "dark" ? "gray.900" : "gray.50",
          borderColor:
            props.colorMode === "dark" ? "rgba(0,0,0,0.15)" : "inherit"
        })
      }
    },
    Modal: {
      baseStyle: (props: any) => ({
        dialog: {
          bg: props.colorMode === "dark" ? "gray.800" : "white"
        },
        body: {
          px: 8,
          py: 6,
          pt: 4
        },
        header: {
          px: 6
        },
        closeButton: {
          top: 3,
          right: 5
        }
      })
    },
    Menu: {
      baseStyle: (props: any) => ({
        list: {
          bg: props.colorMode === "dark" ? "gray.900" : "white",
          border: "1px solid rgb(0 0 0 / 6%)"
        }
      })
    },
    FormLabel: {
      baseStyle: {
        fontSize: "0.9rem",
        mb: 1
      }
    }
  },
  styles: {
    global: (props: GlobalStyleProps): SystemStyleObject => ({
      html: {
        fontSize: {
          base: "14px",
          lg: "16px"
        }
      },
      h1: {
        fontSize: "2.488rem",
        fontWeight: 700,
        lineHeight: 1.2
      },
      h2: {
        fontSize: "2.074rem",
        fontWeight: 700,
        lineHeight: 1.2
      },
      h3: {
        fontSize: "1.728rem",
        fontWeight: 700,
        lineHeight: 1.2
      },
      h4: {
        fontSize: "1.44rem",
        fontWeight: 700,
        lineHeight: 1.2
      },
      body: {
        bg: mode("white", "gray.800")(props),
        color: mode("gray.800", "white")(props),
        touchAction: "manipulation"
      }
    })
  }
});
