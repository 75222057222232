import * as yup from "yup";

export const formAnimalSchema = yup.object().shape({
  nome: yup.string().required("Campo obrigatório")
});

export type FormAnimalValues = {
  nome: string;
  especie: number;
  raca: string;
  sexo: number;
  porte: number;
  idadeAnos: number | null;
  idadeMeses: number | null;
  pelagem: string;
  peso: number;
  corPredominante: string;
  doencasPreexistentes: number[];
  castrado: boolean | string;
  desejaCastrar: boolean | string;
  vacinaEmDia: boolean | string;
  dataUltimaVacinacao: string | null;
  dataObito: string | null;
};
