/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-children-prop */
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputProps,
  Radio,
  RadioGroup,
  Stack,
  Text
} from "@chakra-ui/react";
import { Control, Controller, FieldError } from "react-hook-form";
import { IconType } from "react-icons";
import { TextGray } from "../../Texts/TextGray";

interface Options {
  value: string;
  label: string;
}

interface Props extends InputProps {
  name: string;
  defaultValue?: string;
  isDisabled?: boolean;
  direction?: "row" | "column";
  spacing?: number;
  label?: string;
  icon?: IconType;
  error?: FieldError;
  option: Options[];
  control: Control<any, object>;
}

export const InputRadio = ({
  name,
  defaultValue = "",
  isDisabled,
  direction = "row",
  label,
  error,
  spacing = 4,
  icon: IconComponent,
  control,
  option
}: Props) => {
  return (
    <FormControl isInvalid={!!error}>
      {!!label && (
        <FormLabel htmlFor={name} fontSize="sm">
          <Flex alignItems="center">
            {IconComponent && (
              <TextGray as="span" mr="1.5">
                <IconComponent />
              </TextGray>
            )}
            {label}
          </Flex>
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <RadioGroup
            onChange={field.onChange}
            value={field.value}
            defaultValue={defaultValue}
            isDisabled={isDisabled}
          >
            <Stack direction={direction} spacing={spacing} wrap="wrap">
              {option.map((x) => (
                <Radio key={x.value} value={x.value}>
                  <Text fontSize="sm">{x.label}</Text>
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        )}
      />
      {!!error && (
        <FormErrorMessage mt="1" fontSize="xs">
          {error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
