/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { formatError } from "utils/errorHelper";
import api from "_config/api";

interface Value {
  name: string;
  value: number;
}

interface Foto {
  id: string;
  caminho: string;
  fileUrl: string;
  nome: string;
  size: number;
  sizeFmt: string;
}

interface Doencas {
  value: number;
  name: string;
}

export interface ObterAnimaisResponse {
  id: string;
  nome: string;
  especie: Value;
  sexo: Value;
  porte: Value;
  raca: string;
  pelagem: string;
  peso: number;
  corPredominante: string;
  doencasPreexistentes: Doencas[];
  castrado: boolean;
  desejaCastrar: boolean;
  vacinaEmDia: boolean;
  idadeAnos: number;
  idadeMeses: number;
  dataUltimaVacinacao: string;
  dataObito: string;
  arquivos: Foto[];
}

interface State {
  isRequesting: boolean;
  successPayload: ObterAnimaisResponse[] | null;
  errors: string[];
}

const initialState: State = {
  isRequesting: false,
  successPayload: null,
  errors: []
};

const obterListaAnimaisSlice = createSlice({
  name: "zoonose@obterListaAnimais",
  initialState,
  reducers: {
    obterListaAnimais: (state, action: PayloadAction): void => {
      state.isRequesting = true;
      state.errors = [];
    },
    obterListaAnimaisSuccess: (
      state,
      action: PayloadAction<ObterAnimaisResponse[]>
    ): void => {
      state.isRequesting = false;
      state.successPayload = action.payload;
    },
    obterListaAnimaisFailed: (state, action: PayloadAction<string[]>): void => {
      state.isRequesting = false;
      state.errors = action.payload;
    }
  }
});

function* sagaFunction() {
  try {
    const response: SagaReturnType<typeof api> = yield call(
      api.get,
      `api/v1/cidadao/saude/animais`
    );

    const { data } = response;

    yield put(obterListaAnimaisSuccess(data));
  } catch (error) {
    yield put(obterListaAnimaisFailed(formatError(error)));
  }
}

export const {
  obterListaAnimais,
  obterListaAnimaisSuccess,
  obterListaAnimaisFailed
} = obterListaAnimaisSlice.actions;

export const { reducer } = obterListaAnimaisSlice;

export const saga = takeLatest(obterListaAnimais.type, sagaFunction);
