/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, takeLeading } from "redux-saga/effects";
import { formatError } from "utils/errorHelper";
import api from "_config/api";

export interface AnimalRequest {
  nome: string;
  raca: string;
  especie: number;
  sexo: number;
  porte: number;
  idadeAnos: number | null;
  idadeMeses: number | null;
  pelagem: string;
  peso: number;
  corPredominante: string;
  doencasPreexistentes: number[];
  castrado: boolean;
  desejaCastrar: boolean;
  vacinaEmDia: boolean;
  dataUltimaVacinacao: string | null;
  dataObito: string | null;
  onSuccess?: (idAnimal: string) => void;
}

interface State {
  isRequesting: boolean;
  errors: string[];
}

const initialState: State = {
  isRequesting: false,
  errors: []
};

const cadastrarAnimalSlice = createSlice({
  name: "zoonose@cadastrarAnimal",
  initialState,
  reducers: {
    cadastrarAnimal: (state, action: PayloadAction<AnimalRequest>): void => {
      state.isRequesting = true;
      state.errors = [];
    },
    cadastrarAnimalSuccess: (state): void => {
      state.isRequesting = false;
    },
    cadastrarAnimalFailed: (state, action: PayloadAction<string[]>): void => {
      state.isRequesting = false;
      state.errors = action.payload;
    }
  }
});

function* sagaFunction(params: { type: string; payload: AnimalRequest }) {
  const { onSuccess, ...rest } = params.payload;
  try {
    const response: SagaReturnType<typeof api> = yield call(
      api.post,
      `api/v1/cidadao/saude/animal`,
      rest
    );

    const { data } = response;
    if (onSuccess) onSuccess(data);
    yield put(cadastrarAnimalSuccess());
  } catch (error) {
    yield put(cadastrarAnimalFailed(formatError(error)));
  }
}

export const {
  cadastrarAnimal,
  cadastrarAnimalSuccess,
  cadastrarAnimalFailed
} = cadastrarAnimalSlice.actions;

export const { reducer } = cadastrarAnimalSlice;
export const saga = takeLeading(cadastrarAnimal.type, sagaFunction);
