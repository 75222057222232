import { all } from "redux-saga/effects";

import FotosSaga from "./fotos/sagas";

import { saga as obterListaAnimaisPaged } from "./actions/obterListaAnimais";
import { saga as obterDetalheAnimal } from "./actions/obterDetalheAnimal";
import { saga as cadastrarAnimal } from "./actions/cadastrarAnimal";
import { saga as obterSexoAnimal } from "./actions/obterSexoAnimal";
import { saga as obterDoencas } from "./actions/obterDoencas";
import { saga as obterPorteAnimal } from "./actions/obterPorteAnimal";
import { saga as obterEspecieAnimal } from "./actions/obterEspecieAnimal";
import { saga as editarAnimal } from "./actions/editarAnimal";
import { saga as excluirAnimal } from "./actions/excluirAnimal";
import { saga as cadastrarTutor } from "./actions/cadastrarTutor";

export default all([
  FotosSaga,

  obterListaAnimaisPaged,
  obterDetalheAnimal,
  cadastrarAnimal,
  obterSexoAnimal,
  obterDoencas,
  obterPorteAnimal,
  obterEspecieAnimal,
  editarAnimal,
  excluirAnimal,
  cadastrarTutor
]);
