import { Box, Button, Flex, HStack, Text } from "@chakra-ui/react";
import { ListaAnimais } from "./components/ListaAnimais";
import { RiAddLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const AnimalInicio = () => {
  const navigate = useNavigate();

  return (
    <Flex justifyContent="center" alignItems="center">
      <Box
        w="full"
        maxW="container.xl"
        h="full"
        py={{ base: 8 }}
        px={{ base: 6, sm: 8, xl: 16 }}
        overflowY="visible"
        overflowX="visible"
      >
        <HStack justifyContent="space-between" alignItems="flex-start">
          <Box>
            <Text as="h3">Meus animais</Text>
            <Text>Confira abaixo a lista dos seus animais cadastrados</Text>
          </Box>
          <Button
            colorScheme="green"
            leftIcon={<RiAddLine />}
            size="sm"
            onClick={() => navigate("/zoonose/create")}
          >
            Cadastrar novo pet
          </Button>
        </HStack>
        <ListaAnimais />
      </Box>
    </Flex>
  );
};

export default AnimalInicio;
