import { Flex, useColorModeValue } from "@chakra-ui/react";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { obterConfiguracao } from "store/modules/configuracao/actions/obterConfiguracao";
import { Header } from "./Header";

export const LayoutDefault = () => {
  const barClass = useColorModeValue("bar-light", "bar-dark");
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(obterConfiguracao());
  }, []);

  return (
    <Flex direction="column" flex={1} h="full" className={barClass}>
      <Header />
      <Outlet />
    </Flex>
  );
};
