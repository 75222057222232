import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import { ActionButton } from "./ActionButton";

type Props = {
  direction: "left" | "right";
  isFullscreen: boolean;
  onClick: () => void;
};

const arrowIcon = {
  left: <RiArrowLeftSLine />,
  right: <RiArrowRightSLine />
};

const arrowPosition = {
  left: {
    left: 2
  },
  right: {
    right: 2
  }
};

export const ArrowButton = ({ direction, isFullscreen, onClick }: Props) => {
  return (
    <ActionButton
      isFullscreen={isFullscreen}
      fontSize={isFullscreen ? "2rem" : "1.2rem"}
      position="absolute"
      color="white"
      zIndex={10}
      top="50%"
      transform="translateY(-50%)"
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      {...arrowPosition[direction]}
    >
      {arrowIcon[direction]}
    </ActionButton>
  );
};
