import {
  HStack,
  Divider,
  Center,
  VStack,
  Box,
  Button,
  Text,
  useToast
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputRadio } from "components/Atomos/Form/Elements/InputRadio";
import { InputText } from "components/Atomos/Form/Elements/InputText";
import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useTypedSelector } from "store/hooks";
import { cadastrarAnimal } from "store/modules/zoonose/actions/cadastrarAnimal";
import { editarAnimal } from "store/modules/zoonose/actions/editarAnimal";
import { InputRadioEspecie } from "./Selects/InputRadioEspecie";
import { InputRadioSexo } from "./Selects/InputRadioSexo";
import { InputSelectDoencas } from "./Selects/InputSelectDoencas";
import { InputSelectPorte } from "./Selects/InputSelectPorte";
import { formAnimalSchema, FormAnimalValues } from "./types";
import { UploadFotoAnimal } from "./UploadFotoAnimal";

interface FormCreateAnimalProps {
  idAnimal?: string;
}

export const FormCreateAnimal = ({ idAnimal }: FormCreateAnimalProps) => {
  const [idAnimalResponse, setIdAnimalResponse] = useState<string | null>(null);
  const [hasPhotos, setHasPhotos] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useToast();

  const methods = useForm<FormAnimalValues>({
    resolver: yupResolver(formAnimalSchema)
  });

  const register = methods.register;
  const control = methods.control;
  const errors = methods.formState.errors;
  const handleSubmit = methods.handleSubmit;

  const isCastrado = methods.watch("castrado");

  const { isRequesting } = useTypedSelector(
    (state) => state.zoonose.cadastrarAnimal
  );

  function toNumberOrNull(value: number | null): number | null {
    return value === null ? null : Number(value);
  }

  const onSubmit: SubmitHandler<FormAnimalValues> = (data) => {
    const peso = data?.peso ? data.peso.toString().replace(".", ",") : "";

    if (idAnimal) {
      editarAnimal({
        id: idAnimal,
        ...data,
        peso: Number(peso),
        idadeAnos: toNumberOrNull(data.idadeAnos),
        idadeMeses: toNumberOrNull(data.idadeMeses),
        castrado: data.castrado === "true" ? true : false,
        desejaCastrar: data.desejaCastrar === "true" ? true : false,
        vacinaEmDia: data.vacinaEmDia === "true" ? true : false,
        dataUltimaVacinacao:
          data.dataUltimaVacinacao === "" ? null : data.dataUltimaVacinacao,
        dataObito: data.dataObito === "" ? null : data.dataObito,
        onSuccess: () => {
          toast({
            title: "Edição realizada com sucesso",
            status: "success",
            duration: 2000
          });
        }
      });
    } else {
      dispatch(
        cadastrarAnimal({
          ...data,
          peso: Number(peso),
          idadeAnos: toNumberOrNull(data.idadeAnos),
          idadeMeses: toNumberOrNull(data.idadeMeses),
          castrado: data.castrado === "true" ? true : false,
          desejaCastrar: data.desejaCastrar === "true" ? true : false,
          vacinaEmDia: data.vacinaEmDia === "true" ? true : false,
          dataUltimaVacinacao:
            data.dataUltimaVacinacao === "" ? null : data.dataUltimaVacinacao,
          dataObito: data.dataObito === "" ? null : data.dataObito,
          onSuccess: (id) => {
            toast({
              title: "Cadastro realizado com sucesso",
              status: "success",
              duration: 2000
            });
            setIdAnimalResponse(id);
            if (!hasPhotos) {
              navigate("/zoonose");
            }
          }
        })
      );
    }
  };

  const handleUploadSuccess = () => {
    setIdAnimalResponse(null);
    setHasPhotos(false);
  };

  const checkPhotos = (photos: File[]) => {
    setHasPhotos(photos.length > 0);
  };

  return (
    <FormProvider {...methods}>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <Box
          mt={5}
          padding="2rem 2rem 5rem 2rem"
          gap={5}
          bgColor="white"
          borderRadius="md"
          boxShadow="sm"
        >
          <Text fontSize="xl" fontWeight="medium">
            Dados do Animal{" "}
          </Text>
          <HStack mt={5} alignItems="flex-start" gap={5}>
            <VStack flex={1} gap={3} alignItems="flex-start">
              <Text fontSize="lg" fontWeight="medium">
                Informações Básicas
              </Text>
              <InputText
                label="Nome (Obrigatório)"
                name="nome"
                placeholder="Exemplo: Bilú"
                register={register}
                error={errors.nome}
              />
              <InputRadioEspecie control={control} error={errors.especie} />
              <InputText
                label="Raça *"
                name="raca"
                placeholder="Digite a raça"
                register={register}
                error={errors.raca}
              />
              <InputRadioSexo control={control} error={errors.sexo} />
              <InputSelectPorte control={control} error={errors.porte} />
              <InputText
                label="Pelagem"
                name="pelagem"
                placeholder="Digite uma pelagem"
                register={register}
                error={errors.pelagem}
              />
              <InputText
                label="Cor predominante"
                name="corPredominante"
                placeholder="Digite a cor predominante"
                register={register}
                error={errors.corPredominante}
              />
              <InputText
                label="Idade - Anos"
                name="idadeAnos"
                placeholder="00"
                type="number"
                register={register}
                error={errors.idadeAnos}
              />
              <InputText
                label="Idade - Meses"
                name="idadeMeses"
                placeholder="00"
                type="number"
                register={register}
                error={errors.idadeMeses}
              />
              <UploadFotoAnimal
                idAnimal={idAnimalResponse}
                onUploadSuccess={handleUploadSuccess}
                onDrop={checkPhotos}
              />
            </VStack>
            <Center height="40rem">
              <Divider orientation="vertical" />
            </Center>
            <VStack flex={1} gap={3} alignItems="flex-start">
              <Text fontSize="lg" fontWeight="medium">
                Informações de Saúde e Condições
              </Text>
              <InputText
                label="Peso Kg"
                name="peso"
                placeholder="00"
                register={register}
                error={errors.peso}
              />
              <InputSelectDoencas control={control} />
              <InputRadio
                label="Animal castrado?"
                name="castrado"
                direction="row"
                control={control}
                error={errors.castrado}
                spacing={2}
                option={[
                  {
                    value: "true",
                    label: "Sim"
                  },
                  {
                    value: "false",
                    label: "Não"
                  }
                ]}
              />
              <InputRadio
                label="Deseja castrar?"
                name="desejaCastrar"
                direction="row"
                isDisabled={isCastrado === "true"}
                control={control}
                error={errors.desejaCastrar}
                spacing={2}
                option={[
                  {
                    value: "true",
                    label: "Sim"
                  },
                  {
                    value: "false",
                    label: "Não"
                  }
                ]}
              />
              <InputRadio
                label="Vacinação em dia?"
                name="vacinaEmDia"
                direction="row"
                control={control}
                error={errors.vacinaEmDia}
                spacing={2}
                option={[
                  {
                    value: "true",
                    label: "Sim"
                  },
                  {
                    value: "false",
                    label: "Não"
                  }
                ]}
              />
              <InputText
                label="Data da última vacinação"
                name="dataUltimaVacinacao"
                type="date"
                register={register}
                error={errors.dataUltimaVacinacao}
              />
              <InputText
                label="Data de óbito"
                name="dataObito"
                type="date"
                register={register}
                error={errors.dataObito}
              />
            </VStack>
          </HStack>
        </Box>
        <HStack w="full" mt={5} justifyContent="flex-end">
          <Button
            colorScheme="green"
            variant="outline"
            isDisabled={isRequesting}
            onClick={() => navigate("/zoonose")}
          >
            Cancelar
          </Button>
          <Button type="submit" colorScheme="green" isLoading={isRequesting}>
            {idAnimal ? "Editar Animal" : "Cadastrar Animal"}
          </Button>
        </HStack>
      </Box>
    </FormProvider>
  );
};
