import { combineReducers } from "redux";

import { reducer as obterDetalheTipoRequerimento } from "./actions/obterDetalheTipoRequerimento";
import { reducer as obterTiposRequerimentos } from "./actions/obterTiposRequerimentos";
import { reducer as cadastrarSolicitacao } from "./actions/obterTiposRequerimentos";
import { reducer as obterDetalheSolicitacao } from "./actions/obterDetalheSolicitacao";
import { reducer as obterMinhasSolicitacoes } from "./actions/obterMinhasSolicitacoes";
import { reducer as obterUltimasSolicitacoes } from "./actions/obterUltimasSolicitacoes";
import { reducer as downloadArquivoPendencia } from "./actions/downloadArquivoPendencia";
import { reducer as downloadArquivoSolicitacao } from "./actions/downloadArquivoSolicitacao";
import { reducer as responderPendencia } from "./actions/responderPendencia";
import { reducer as downloadArquivoRespostaPendencia } from "./actions/downloadArquivoRespostaPendencia";
import { reducer as downloadArquivoEtapaSolicitacao } from "./actions/downloadArquivoEtapaSolicitacao";

export const licenciamentourbano = combineReducers({
  obterDetalheTipoRequerimento,
  obterTiposRequerimentos,
  cadastrarSolicitacao,
  obterDetalheSolicitacao,
  obterMinhasSolicitacoes,
  obterUltimasSolicitacoes,
  downloadArquivoSolicitacao,
  downloadArquivoPendencia,
  responderPendencia,
  downloadArquivoRespostaPendencia,
  downloadArquivoEtapaSolicitacao
});
