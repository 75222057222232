/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, takeLeading } from "redux-saga/effects";
import { formatError } from "utils/errorHelper";
import api from "_config/api";

export interface AnimalRequest {
  idAnimal: string;
  nome: string;
  cpf: string;
  rg: string;
  email: string;
  dataNascimento: string;
  celular: string;
  telefone: string;
  cns: string;
  participaProgramaSocial: boolean;
  isProprietario: boolean;
  cep: string;
  logradouro: string;
  quadra: string;
  numero: string;
  complemento: string;
  bairro: string;
  uf: string;
  municipio: string;
  onSuccess?: (idTutor: string) => void;
}

interface State {
  isRequesting: boolean;
  errors: string[];
}

const initialState: State = {
  isRequesting: false,
  errors: []
};

const cadastrarTutorSlice = createSlice({
  name: "zoonose@cadastrarTutor",
  initialState,
  reducers: {
    cadastrarTutor: (state, action: PayloadAction<AnimalRequest>): void => {
      state.isRequesting = true;
      state.errors = [];
    },
    cadastrarTutorSuccess: (state): void => {
      state.isRequesting = false;
    },
    cadastrarTutorFailed: (state, action: PayloadAction<string[]>): void => {
      state.isRequesting = false;
      state.errors = action.payload;
    }
  }
});

function* sagaFunction(params: { type: string; payload: AnimalRequest }) {
  const { onSuccess, ...rest } = params.payload;
  try {
    const response: SagaReturnType<typeof api> = yield call(
      api.post,
      `api/v1/cidadao/saude/responsavel`,
      rest
    );

    const { data } = response;
    if (onSuccess) onSuccess(data);
    yield put(cadastrarTutorSuccess());
  } catch (error) {
    yield put(cadastrarTutorFailed(formatError(error)));
  }
}

export const { cadastrarTutor, cadastrarTutorSuccess, cadastrarTutorFailed } =
  cadastrarTutorSlice.actions;

export const { reducer } = cadastrarTutorSlice;
export const saga = takeLeading(cadastrarTutor.type, sagaFunction);
