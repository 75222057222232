import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { Outlet } from "react-router-dom";
import api from "_config/api";

export const Protected = () => {
  const isAuthenticated = useIsAuthenticated();
  const authHeader = useAuthHeader();

  if (!isAuthenticated) {
    return null;
  }

  if (authHeader) {
    api.defaults.headers.common["Authorization"] = authHeader;
  }

  return <Outlet />;
};
