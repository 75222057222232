/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, takeLeading } from "redux-saga/effects";
import { formatError } from "utils/errorHelper";
import api from "_config/api";

export interface CadastrarSolicitacaoRequest {
  respostas: FormData;
  onSuccess?: (idSolicitacao: string) => void;
}

interface State {
  isRequesting: boolean;
  errors: string[];
}

const initialState: State = {
  isRequesting: false,
  errors: []
};

const cadastrarSolicitacaoSlice = createSlice({
  name: "licenciamentourbano@cadastrarSolicitacao",
  initialState,
  reducers: {
    cadastrarSolicitacao: (
      state,
      action: PayloadAction<CadastrarSolicitacaoRequest>
    ): void => {
      state.isRequesting = true;
      state.errors = [];
    },
    cadastrarSolicitacaoSuccess: (state): void => {
      state.isRequesting = false;
    },
    cadastrarSolicitacaoFailed: (
      state,
      action: PayloadAction<string[]>
    ): void => {
      state.isRequesting = false;
      state.errors = action.payload;
    }
  }
});

function* sagaFunction(params: {
  type: string;
  payload: CadastrarSolicitacaoRequest;
}) {
  const { onSuccess, respostas } = params.payload;
  try {
    const response: SagaReturnType<typeof api> = yield call(
      api.post,
      `api/v1/cidadao/licenciamentourbano`,
      respostas,
      { headers: { "Content-Type": "multipart/form-data" } }
    );

    const { data } = response;
    if (onSuccess) onSuccess(data);

    yield put(cadastrarSolicitacaoSuccess());
  } catch (error) {
    yield put(cadastrarSolicitacaoFailed(formatError(error)));
  }
}

export const {
  cadastrarSolicitacao,
  cadastrarSolicitacaoSuccess,
  cadastrarSolicitacaoFailed
} = cadastrarSolicitacaoSlice.actions;

export const { reducer } = cadastrarSolicitacaoSlice;
export const saga = takeLeading(cadastrarSolicitacao.type, sagaFunction);
