/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { formatError } from "utils/errorHelper";
import api from "_config/api";

export interface uploadFotosAnimalRequest {
  idAnimal: string;
  fotos: File[];
  onSuccess?: () => void;
}

interface State {
  isRequesting: boolean;
  errors: string[];
}

const initialState: State = {
  isRequesting: false,
  errors: []
};
const uploadFotosAnimalSlice = createSlice({
  name: "zoonose.fotos.fotos@uploadFotosAnimal",
  initialState,
  reducers: {
    uploadFotosAnimal: (
      state,
      action: PayloadAction<uploadFotosAnimalRequest>
    ): void => {
      state.isRequesting = true;
      state.errors = [];
    },
    uploadFotosAnimalSuccess: (state): void => {
      state.isRequesting = false;
    },
    uploadFotosAnimalFailed: (state, action: PayloadAction<string[]>): void => {
      state.isRequesting = false;
      state.errors = action.payload;
    }
  }
});

function* sagaFunction(params: {
  type: string;
  payload: uploadFotosAnimalRequest;
}) {
  try {
    const { onSuccess, fotos, idAnimal } = params.payload;

    const formData = new FormData();

    fotos.forEach((foto, i) => {
      formData.append(`fotos[${i}].foto`, foto as Blob);
      formData.append(`fotos[${i}].nome`, foto.name);
    });

    yield call(
      api.post,
      `api/v1/cidadao/saude/animal/${idAnimal}/foto`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data"
        }
      }
    );

    if (onSuccess) onSuccess();

    yield put(uploadFotosAnimalSuccess());
  } catch (error) {
    yield put(uploadFotosAnimalFailed(formatError(error)));
  }
}

export const {
  uploadFotosAnimal,
  uploadFotosAnimalSuccess,
  uploadFotosAnimalFailed
} = uploadFotosAnimalSlice.actions;
export const { reducer } = uploadFotosAnimalSlice;
export const saga = takeLatest(uploadFotosAnimal.type, sagaFunction);
