import { Route, Routes } from "react-router-dom";
import AnimalInicio from "pages/Zoonose/Home";
import CreateAnimal from "pages/Zoonose/CreateAnimal";

export const ZoonoseRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AnimalInicio />} />
      <Route path="/create" element={<CreateAnimal />} />
      <Route path="/:idAnimal" element={<CreateAnimal />} />
    </Routes>
  );
};
