import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { RiArrowLeftLine } from "react-icons/ri";
import { Link, useParams } from "react-router-dom";
import { FormCreateAnimal } from "./components/FormCreateAnimal";

const AnimalInicio = () => {
  const { idAnimal } = useParams<{ idAnimal: string }>();

  return (
    <Flex justifyContent="center" alignItems="center">
      <Box
        w="full"
        maxW="container.xl"
        h="full"
        py={{ base: 8 }}
        px={{ base: 6, sm: 8, xl: 16 }}
        overflowY="visible"
        overflowX="visible"
      >
        <Link to={"/zoonose"}>
          <Button colorScheme="gray" color="gray.900" variant="link" gap={2}>
            <RiArrowLeftLine /> Voltar
          </Button>
        </Link>
        <Text as="h3">{idAnimal ? "Edição" : "Cadastrar novo pet"}</Text>

        <FormCreateAnimal idAnimal={idAnimal} />
      </Box>
    </Flex>
  );
};

export default AnimalInicio;
