export function getPreviewUploadFoto(img: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const result = reader.result as string | null;
      if (result) {
        resolve(result);
      } else {
        reject(new Error("Failed to read the file."));
      }
    };

    reader.onerror = () => {
      reject(new Error("An error occurred while reading the file."));
    };

    reader.readAsDataURL(img);
  });
}
