/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, SagaReturnType, takeLatest } from "redux-saga/effects";
import { formatError } from "utils/errorHelper";
import api from "_config/api";

export interface Solicitacao {
  id: string;
  nome: string;
  solicitacaoEtapa: {
    value: number;
    name: string;
  };
  status?: {
    value: number;
    name: string;
  };
  dataSolicitacao: string;
  dataSolicitacaoFormatada: string;
  protocolo: string;
}

interface State {
  isRequesting: boolean;
  successPayload: Solicitacao[];
  errors: string[];
}

const initialState: State = {
  isRequesting: false,
  successPayload: [],
  errors: []
};

const obterMinhasSolicitacoesSlice = createSlice({
  name: "licenciamentourbano@obterMinhasSolicitacoes",
  initialState,
  reducers: {
    obterMinhasSolicitacoes: (state): void => {
      state.isRequesting = true;
      state.errors = [];
    },
    obterMinhasSolicitacoesSuccess: (
      state,
      action: PayloadAction<Solicitacao[]>
    ): void => {
      state.isRequesting = false;
      state.successPayload = action.payload;
    },
    obterMinhasSolicitacoesFailed: (
      state,
      action: PayloadAction<string[]>
    ): void => {
      state.isRequesting = false;
      state.errors = action.payload;
    }
  }
});

function* sagaFunction() {
  try {
    const response: SagaReturnType<typeof api> = yield call(
      api.get,
      `api/v1/cidadao/licenciamentourbano/solicitacoes`
    );

    const { data } = response;

    yield put(obterMinhasSolicitacoesSuccess(data));
  } catch (error) {
    yield put(obterMinhasSolicitacoesFailed(formatError(error)));
  }
}

export const {
  obterMinhasSolicitacoes,
  obterMinhasSolicitacoesSuccess,
  obterMinhasSolicitacoesFailed
} = obterMinhasSolicitacoesSlice.actions;

export const { reducer } = obterMinhasSolicitacoesSlice;

export const saga = takeLatest(obterMinhasSolicitacoes.type, sagaFunction);
